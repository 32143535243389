// This loads the farm details from the Farmers Collection.  It is sends in an
// array with the farms names to load and then returns an array with their details
// loaded from the database
import firebase from "../../components/Firebase.js";

export default function LoadFarmDetails(farms, setFarmDetails, setFarmLoading) {
  const database = firebase.firestore();
  // A temporary array to hold the data while it loads each farm
  const dataTranfer = [];
  // If the first value in the array is "No Farms" than there aren't any farms
  // to load and exit this function
  if (farms[0] === "No Farms") {
    setFarmLoading(true);
  } else {
    // Cycle through each farm and load the details and add to the array.  On the
    // last one when the farms array and the dataTranfer length are the same then
    // set the loading to done and the dataTransfer to the hook setFarmDetails.
    farms.forEach((farm) => {
      // Find the document containing the farm's details
      const farmDocRef = database.collection("Farmers").doc(farm);

      // Load the farm document
      farmDocRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            dataTranfer.push(doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }

          // Check to see if this is the last farm and if it then set the loading
          // to done and set the farmDetails value
          if (dataTranfer.length === farms.length) {
            setFarmDetails(dataTranfer);
            setFarmLoading(true);
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          setFarmLoading(true);
        });
    });
  }
  // return nothing as the values are set by the hooks
  return null;
}
