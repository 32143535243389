// This function runs when a distribution location is updating the payment history
// of a user.  This will find the existing payment history and update it using a
// transaction in case it is being modified by another user.
import firebase from "../../../components/Firebase.js";
import YYYYMMDDConverter from "../../../pages/BasketPage/Functions/YYYYMMDDConverter.js";

export default function EditCustomerPayment(
  newTotal,
  orderNotes,
  orderDate,
  imported,
  distributionLocation,
  user,
  updatePaymentHistory,
) {
  //  selectedDate : MM-DD-YYYY
  //   importedOrder :
  // 	  DistributionLocation :
  // 		 Location : XXXX
  // 	   date : MM-DD-YYYY
  // 	   communityOrders :
  //      firstName-lastName-userId-date :
  //      deposits: { timestamp : $XX.XX, timestamp : $XX.XX,
  //                            timestamp : $XX.XX}
  //      totalOwed : $XX.XX
  //      DistributionLocation : XXXX
  //      Owed History : [timestamp : $XX.XX, timestamp : $XX.XX, timestamp : $XX.XX]
  //      OrderNotes : XXXXXX
  //      orderRefunds :{ timestamp : $XX.XX, timestamp : $XX.XX,
  //                      timestamp : $XX.XX}
  // 	 communityOrders
  // 		firstName-lastName-userId-date :
  //      deposits: { timestamp : $XX.XX, timestamp : $XX.XX,
  //                            timestamp : $XX.XX}
  //      totalOwed : $XX.XX
  //      DistributionLocation : XXXX
  //      Owed History : [timestamp : $XX.XX, timestamp : $XX.XX, timestamp : $XX.XX]
  //      OrderNotes : XXXXXX
  //      orderRefunds :{ timestamp : $XX.XX, timestamp : $XX.XX,
  //                      timestamp : $XX.XX}
  let orderDatePaymentHistory = {};
  // A field that has the orderDate with "Exists" concatenated to the end to help
  // with searching for the document in the database.
  const orderExistsField = orderDate.concat("Exists");

  // set up the datebase.
  const database = firebase.firestore();
  // eslint-disable-next-line no-unused-vars
  const batch = database.batch();

  // Find the document that has the payment history that we are applying for.
  const paymentHistoryDocRef = database
    .collection("PaymentHistory")
    .where(orderExistsField, "==", true);

  // Pull the document and find the matching document.
  paymentHistoryDocRef
    .get()
    .then((collection) => {
      collection.docs.forEach((doc) => {
        if (doc.exists) {
          // Find the document's name by reading the createdDate variable which is
          // the same.
          const documentName = doc.data().createdDate;
          // Run a transaction since payment can be updated by adding or subtracting values based off
          // the value stored in the database we have to make sure that the value is up
          // to date and accurate.
          return database.runTransaction((transaction) => {
            // Store the document's reference.
            const paymentHistoryDocRef = database
              .collection("PaymentHistory")
              .doc(documentName);

            // This code may get re-run multiple times if there are conflicts.
            return transaction
              .get(paymentHistoryDocRef)
              .then((paymentHistoryDoc) => {
                if (!paymentHistoryDoc.exists) {
                  // eslint-disable-next-line no-throw-literal
                  throw "Document does not exist!";
                }

                // Load the full document of this order Date.
                const documentData = paymentHistoryDoc.data();

                const index = Object.keys(doc.data()).indexOf(orderDate);
                // The order exists in this document.
                if (index !== -1) {
                  // Store the current orderDate.
                  orderDatePaymentHistory = doc.data()[orderDate];

                  // Store the communityOrders of this date.
                  let communityOrdersPaymentHistory = {};

                  if (!imported) {
                    // The dictionary that saves the community orders payment history.  This is used
                    // later and added into the larger payment history that is saved to the database.  This
                    // is specifically for the community location's orders not their imported orders.
                    communityOrdersPaymentHistory =
                      orderDatePaymentHistory.communityOrders;
                  } else {
                    // Store the importedOrder of this date.
                    communityOrdersPaymentHistory =
                      orderDatePaymentHistory.importedOrder[
                        distributionLocation
                      ].communityOrders;
                  }

                  // The current time to record the payment change.
                  const now = YYYYMMDDConverter();

                  // Set the new orders amount to their old amount.
                  communityOrdersPaymentHistory[user].totalOwed =
                    parseFloat(newTotal);
                  // Add this new order's total and date to the orderHistory.
                  communityOrdersPaymentHistory[user].orderHistory[now] =
                    communityOrdersPaymentHistory[user].totalOwed;
                  // Update the notes to inlcude the additional order added.
                  communityOrdersPaymentHistory[user].orderNotes =
                    communityOrdersPaymentHistory[user].orderNotes.concat(
                      `${orderNotes} ${now}. `,
                    );

                  if (!imported) {
                    // Update the payment history to include the community orders.
                    orderDatePaymentHistory.communityOrders =
                      communityOrdersPaymentHistory;
                  } else {
                    // Update the payment history to include the community orders from all the imported
                    // locations.
                    orderDatePaymentHistory.importedOrder[
                      distributionLocation
                    ].communityOrders = communityOrdersPaymentHistory;
                  }
                }
                // This order doesn't exist in this document so you can't update it.
                else {
                  console.log("This order cannot be updated.");
                }

                // Update the full documentName to include the changes for this date.
                documentData[orderDate] = orderDatePaymentHistory;

                // Complete the transaction.  If the document has been changed then
                // this document has to go again.
                transaction.update(paymentHistoryDocRef, documentData);
                // Update the paymentHistory dictionary
                updatePaymentHistory(documentData, orderDate);
              });
          });
        } else {
          console.log("UNDEFINED");
        }
      });
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
