// This loads the orders of a distribution location it takes in the selected
// distribution location and the location Details and then finds the orders of
// that distribution location
import React, { useEffect } from "react";
import useFirestore from "./useFirestore";

export default function useIndividualWallet(
  userId,
  setCommunityWallet,
  setLoading,
) {
  const database = useFirestore();
  // holds the errors if there are any
  const [error, setError] = React.useState();

  useEffect(() => {
    if (!userId) return; // Ensure userId is available before fetching

    setLoading(true);

    // Set the doc reference to the Users -> userId -> Orders collection
    const walletsDocRef = database.collection("CommunityWallets").doc(userId);

    walletsDocRef
      .get()
      .then((walletDoc) => {
        if (walletDoc.exists) {
          setCommunityWallet(walletDoc.data());
        } else {
          console.log("No such document!");
          setCommunityWallet({});
        }
      })
      .catch((error) => {
        console.error("Error getting document:", error);
        setError(error.message || "Error fetching wallet data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [database, userId]);

  // return the error, orders dictionary and the loading
  return error;
}
