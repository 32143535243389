// This will load all the user's wallets on the admin page.
import React, { useEffect, useState } from "react";
import LoadingContent from "../../../components/LoadingContent.jsx";
import useCommunityWallets from "../../../functions/Firestore/useCommunityWallets.js";
import CommunityMemberSearch from "../Components/CommunityMemberSearch.jsx";
import TransactionHistoryDialog from "../Dialogs/TransactionHistoryDialog.jsx";
import AdjustMemberWallet from "../Dialogs/AdjustMemberWallet.jsx";

export default function CommunityWalletsTab() {
  // Load teh wallets.
  const [communityWalletsTemp, loading, error] = useCommunityWallets();
  // Sets the wallet states.
  const [communityWallets, setCommunityWallets] = useState([]);
  // Loads the wallets.
  useEffect(() => {
    setCommunityWallets([...communityWalletsTemp]);
  }, [communityWalletsTemp]);

  if (loading) {
    return (
      <div>
        <LoadingContent inline={true} />
      </div>
    );
  } else {
    if (error) {
      return (
        <div>
          <p>Error loading community wallets: {error}</p>
        </div>
      );
    }

    return (
      <div>
        <h2>Community Wallet Transactions</h2>
        <CommunityMemberSearch
          communityWallets={communityWallets}
          setCommunityWallets={setCommunityWallets}
        />
        <table border="1">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Amount</th>
              <th>Transaction History</th>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {communityWallets.map((wallet, index) => (
              <tr key={index}>
                <td>{wallet.firstName}</td>
                <td>{wallet.lastName}</td>
                <td>${wallet.Total?.toFixed(2) || "0.00"}</td>
                <td>
                  <TransactionHistoryDialog
                    buttonSize="small"
                    buttonText="TRANSACTION HISTORY"
                    transactionHistory={wallet.transactionHistory}
                  />
                </td>
                <td>
                  <AdjustMemberWallet
                    communityWallets={communityWallets}
                    index={index}
                    setCommunityWallets={setCommunityWallets}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
