// Load the wallet for the individual's wallet tab.
import React, { useState } from "react";
import LoadingContent from "../../../components/LoadingContent.jsx";
import useIndividualWallet from "../../../functions/Firestore/useIndividualWallet.js";
import TransactionHistoryDialog from "../Dialogs/TransactionHistoryDialog.jsx";

export default function IndividualWalletTab({ userId }) {
  // Creates the user's wallet.
  const [communityWallet, setCommunityWallet] = useState({});
  const [loading, setLoading] = React.useState(true);
  // Loads the user's wallet.
  const error = useIndividualWallet(userId, setCommunityWallet, setLoading);

  if (loading) {
    return (
      <div>
        <LoadingContent inline={true} />
      </div>
    );
  } else {
    if (error) {
      return (
        <div>
          <p>Error loading volunteer schedule: {error}</p>
        </div>
      );
    }

    return (
      <div>
        <h2>Your F2PM Wallet</h2>
        <table border="1">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Transaction History</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${communityWallet.Total?.toFixed(2) || "0.00"}</td>
              <td>
                <TransactionHistoryDialog
                  buttonSize="small"
                  buttonText="TRANSACTION HISTORY"
                  transactionHistory={communityWallet?.transactionHistory || []}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
