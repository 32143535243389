import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import "../App.css";
import "../styles/MyAccount.css";
import Tabs from "../components/Tabs.js";
import Menu from "../components/Menu.js";
import PlacingOrderDialog from "../components/dialogs/PlacingOrderDialog.jsx";
import CustomizedSnackbars from "../components/snackBars/Snackbar.js";
import DisplayPantry from "../components/Pantry/DisplayPantry.jsx";
import firebase from "../components/Firebase.js";
import { AuthContext } from "../components/authentication/Auth.js";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlaceOrder from "../functions/PlaceOrder.js";
import PersonalInfo from "./MyAccountPage/Tabs/PersonalInfo.jsx";
import OrganisationInfo from "./MyAccountPage/Tabs/OrganisationInfo.jsx";
import Address from "./MyAccountPage/Tabs/Address.jsx";
import Communications from "./MyAccountPage/Tabs/Communications.jsx";
import PickupLocation from "./MyAccountPage/Tabs/PickupLocation.jsx";
import OrderHistory from "./MyAccountPage/Tabs/OrderHistory.jsx";
import FarmTab from "./MyAccountPage/Tabs/FarmTab.jsx";
import EndRecurringDelivery from "./MyAccountPage/Functions/EndRecurringDelivery.js";
import LoadRecurringOrders from "./MyAccountPage/Functions/LoadRecurringOrders.js";
import OrderAgain from "./MyAccountPage/Functions/OrderAgain.js";
import CommunityDistributionLocation from "./MyAccountPage/Tabs/CommunityDistributionLocation.jsx";
import CommunityAdmin from "./MyAccountPage/Tabs/CommunityAdmin.jsx";
import CommunityIndividual from "./MyAccountPage/Tabs/CommunityIndividual.jsx";
import PaymentHistoryTotalsDialog from "./MyAccountPage/Dialogs/PaymentHistoryTotalsDialog.jsx";
import LoadingContent from "../components/LoadingContent.jsx";
import Footer from "../components/Footer.jsx";
import LoadCommunityOrders from "./MyAccountPage/Functions/LoadingOrderHistory/LoadCommunityOrders.js";
import IndividualWalletTab from "./MyAccountPage/Tabs/IndividualWalletTab.jsx";

// Includes this to allow for the OrderLog to be sent to children.  It also includes
// the order payment history.
export const ChangeOrderLogContext = React.createContext();
export const CommunityOrderChunksContext = React.createContext();

export default function MyAccount() {
  // The hook where the data downloaded from the database is stored.  It is intialized
  // to have the contents of a document that is empty.  That way the map function doesn't
  // complain
  // const [userInfo, setUserInfo] = useState([{email: [""], firstName: "", lastName: "", phoneNumber: 0, restaurantAddress: "", restaurantCity: "", restaurantCountry: "", restaurantName: "", restaurantPostalCode: "", restaurantProvTerr: "", userId: ""}]);

  const { currentUser, userInfo, handleUserInfoChange } =
    useContext(AuthContext);
  // The location of the page that sent you here.  It may be empty.
  const location = useLocation();

  if (
    userInfo.farms === undefined &&
    userInfo.userType !== "individual" &&
    !!currentUser
  ) {
    userInfo.farms = ["No farms available please contact developer"];
    handleUserInfoChange(userInfo, true);
  }

  // Stores the information that is stored in the Database DistributionLocation -> Vancouver
  // -> locationName
  const [distributionLocationInformation, setDistributionLocationInformation] =
    useState({});
  // This is set to false until the data from the database been loaded
  const [loading, setLoading] = useState(false);

  // Is set to true once the distributionLocation information has been loaded
  const [loadingDistributionLocation, setLoadingDistributionLocation] =
    useState(false);

  // Is set to true once the paymentHistory has been loaded.
  const [loadingPaymentHistory, setLoadingPaymentHistory] = useState(false);

  // The order's placed including all orders.
  const [orderLog, setOrderLog] = useState([
    { foodList: [""], orderDate: "", status: "" },
  ]);

  // The order PaymentHistory.  Load the most recent one.
  const [orderPaymentHistory, setOrderPaymentHistory] = useState({});

  // These are the leftovers in the distributionLocation's pantry
  const [leftovers, setLeftovers] = useState([]);

  // communityOrderChunks are in {orderdate: [communityOrders, overFlowCommunityOrders1, etc.]}
  const [communityOrderChunks, setCommunityOrderChunks] = useState({});

  // This is used to update the orderLog so that the page rerenders when it is changed
  function updateOrderLog(orderLogTemp) {
    // Ensure orderLogTemp is an array; if not, default to an empty array
    setOrderLog(
      Array.isArray(orderLogTemp)
        ? orderLogTemp
        : [{ foodList: [""], orderDate: "", status: "" }],
    );
  }

  // This is used to update the paymentHistory dictionary when the price of a user's
  // order is changed.
  function updatePaymentHistory(paymentHistoryTemp, orderDateTemp) {
    // Read the current orderPaymentHistory.
    const orderPaymentHistoryTemp = { ...orderPaymentHistory };
    // Update the specific order date.
    orderPaymentHistoryTemp[orderDateTemp] = {
      ...paymentHistoryTemp[orderDateTemp],
    };
    // Set the order payment history.
    setOrderPaymentHistory({ ...orderPaymentHistoryTemp });
  }

  // This is an array that holds all the current recurring orders of the user
  const [recurringOrders, setRecurringOrders] = useState([]);

  // This is true while the order is being placed.  This gives time for the website
  // to send the email to the user.
  const [placingOrder, setPlacingOrder] = useState(false);

  // Once the user has loaded their recurring orders and if no orders exist than
  // this is set to true.
  const [noRecurringOrders, setNoRecurringOrders] = useState(false);

  // Contains the last loaded order in the orderLog
  const [lastLoadedOrder, setLastLoadedOrder] = useState("");

  // Contains the lastLoaded paymentHistory document.
  const [lastLoadedPaymentHistory, setLastLoadedPaymentHistory] = useState("");

  const database = firebase.firestore();

  // How many orders to load in each batch
  const loadLimit = 5;

  // Saves whether the snackBar that displays after placing order is open or not
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  useEffect(() => {
    LoadCommunityOrders(
      database,
      currentUser,
      loadLimit,
      setLastLoadedOrder,
      setOrderLog,
      setLoading,
      {},
      setCommunityOrderChunks,
    );
    // If the user is a distributionLocation the we also have to load the pantry
    // Items and load what is stored in the distributionLocation collection for
    // this distributionLocation
    if (userInfo.userType === "distributionLocation") {
      let organisationName = "Default";
      if (userInfo.organisationName !== undefined) {
        organisationName = userInfo.organisationName;
      }
      const pantryDocRef = database.collection("Pantry").doc(organisationName);
      // Pulls the user information from the database.
      pantryDocRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const dataTransfer = [];
            Object.keys(doc.data()).forEach((item) => {
              dataTransfer.push(doc.data()[item]);
            });
            // Set the leftovers to equal what is stored in the pantry
            setLeftovers([...dataTransfer]);
            // setLeftovers(doc.data().leftovers)
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });

      const distributionLocationsDocRef = database
        .collection("DistributionLocations")
        .doc("Vancouver");
      distributionLocationsDocRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            setDistributionLocationInformation(data[userInfo.organisationName]);
            setLoadingDistributionLocation(true);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });

      // Load the last two payment histories.  This will order them by their created date and then
      // pick the two newest ones since we load 5 at the start so we know that at least one of them
      // will have the payment histories of the current orders.
      const paymentHistoryDocRef = database
        .collection("PaymentHistory")
        .orderBy("createdDate", "desc")
        .limit(2);
      // Update the Payment History to store information to help track payments.
      paymentHistoryDocRef
        .get()
        .then((collection) => {
          let dataTransfer = {};

          collection.docs.forEach((doc) => {
            if (doc.exists) {
              dataTransfer = Object.assign({}, dataTransfer, doc.data());
              // Set the last loaded document.
              setLastLoadedPaymentHistory(doc);
            }
          });
          setOrderPaymentHistory({ ...dataTransfer });
          setLoadingPaymentHistory(true);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    } else {
      setLoadingPaymentHistory(true);
      setLoadingDistributionLocation(true);
    }
  }, [currentUser.uid, database]);

  // Makes sure that when a user changes their info and the database is updated
  // we update the userInfo as well so that doesn't become stale.
  function handleInfoUpdate(updatedUserInfo) {
    const tempUserInfo = { ...userInfo };
    Object.keys(updatedUserInfo).forEach((key) => {
      if (key !== "open") {
        tempUserInfo[key] = updatedUserInfo[key];
      }
    });
    handleUserInfoChange(tempUserInfo, true);
  }

  // This is to load more orders at 5 at a time
  function LoadMore() {
    LoadCommunityOrders(
      database,
      currentUser,
      loadLimit,
      setLastLoadedOrder,
      setOrderLog,
      setLoading,
      communityOrderChunks,
      setCommunityOrderChunks,
      true,
      orderLog,
      lastLoadedOrder,
    );

    // If the user is a distribution location then we also need to load the payment history.
    if (userInfo.userType === "distributionLocation") {
      // Load an additional payment History document.
      const paymentHistoryDocRef = database
        .collection("PaymentHistory")
        .orderBy("createdDate", "desc")
        .startAfter(lastLoadedPaymentHistory)
        .limit(1);
      // Update the Payment History to store information to help track payments.
      paymentHistoryDocRef
        .get()
        .then((collection) => {
          // Equal to the currentOrders.
          let dataTransfer = { ...orderPaymentHistory };

          collection.docs.forEach((doc) => {
            if (doc.exists) {
              dataTransfer = Object.assign({}, dataTransfer, doc.data());
              // Set the last loaded document.
              setLastLoadedPaymentHistory(doc);
            }
          });
          setOrderPaymentHistory({ ...dataTransfer });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    }
  }

  // handles the Saved any changed snackbar closing
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  // This function runs when you place the order again.  It runs the function
  // placeOrder and then it adds this order to the orderLog so it displays for
  // the user in real time and then shows the snackBar confirming the action.
  function orderAgain(order) {
    OrderAgain(
      order,
      setOrderLog,
      setPlacingOrder,
      PlaceOrder,
      userInfo,
      setSnackBarOpen,
      orderLog,
    );
  }

  // Hacked function that copies orders from other days to another day
  // function handleCopy(){
  //   const batch = database.batch();
  //   const futureOrderDocRef = database.collection('Users').doc("O9OYhWoHzDPJ1SXmWKa1VSOBBfG2").collection("Orders").doc("202105031620059128619");
  //   futureOrderDocRef.get().then((doc) => {
  //     if (doc.exists) {
  //       let test = {}
  //       console.log("doc.data is", doc.data())
  //
  //       test = doc.data()
  //
  //       const testDocRef = database.collection('Users').doc("O9OYhWoHzDPJ1SXmWKa1VSOBBfG2").collection("Orders").doc("202105101620695049879");
  //       console.log("test is", test)
  //       batch.set(testDocRef, test )
  //
  //       batch.commit();
  //
  //     } else {
  //       // doc.data() will be undefined in this case
  //         console.log("No such document!");
  //     }
  //   }).catch(function(error) {
  //             console.log("Error getting document:", error)
  //           });
  //
  //
  // }

  // Checks if the data has been loaded yet
  if (loading && loadingDistributionLocation && loadingPaymentHistory) {
    return (
      <div>
        <Menu />
        <div className="Page-Market">
          <div className="Page-Header">
            <h1 className="w3-xxlarge Bold-Text">My Account</h1>
          </div>
          {(userInfo.userType === "restaurant" ||
            userInfo.userType === "admin") && (
            <span className="My-Menu-Button">
              <Button
                component={Link}
                to={"/Menu/" + userInfo.restaurantName}
                variant="contained"
                color="primary"
              >
                My Restaurant Menu
              </Button>
            </span>
          )}
          <Tabs>
            {userInfo.userType === "distributionLocation" ? (
              <div label="Organisation Information">
                <OrganisationInfo
                  handleInfoUpdate={handleInfoUpdate}
                  userInfo={userInfo}
                  currentUser={currentUser}
                />
              </div>
            ) : (
              <div label="Personal Information">
                <PersonalInfo
                  restaurantName={userInfo.restaurantName}
                  firstName={userInfo.firstName}
                  lastName={userInfo.lastName}
                  email={userInfo.email}
                  phoneNumber={userInfo.phoneNumber}
                  handleInfoUpdate={handleInfoUpdate}
                  userInfo={userInfo}
                  currentUser={currentUser}
                  open={location.state?.fromIncompleteAccount}
                />
              </div>
            )}

            {userInfo.userType === "individual" ? (
              <div label="Pickup Location">
                {userInfo.pickupLocation === undefined ? (
                  <Link
                    to={{
                      pathname: "/DistributionLocationSelection",
                      query: "/MyAccount",
                    }}
                  >
                    <Button color="primary">
                      PLEASE CHOOSE A PICKUP LOCATION
                    </Button>
                  </Link>
                ) : (
                  <PickupLocation userInfo={userInfo} />
                )}
              </div>
            ) : (
              <div label="Address">
                <Address
                  restaurantName={userInfo.restaurantName}
                  streetAddress={userInfo.restaurantAddress}
                  country={userInfo.restaurantCountry}
                  city={userInfo.restaurantCity}
                  provTerr={userInfo.restaurantProvTerr}
                  postalCode={userInfo.restaurantPostalCode}
                  latitude={userInfo.restaurantLatitude}
                  longitude={userInfo.restaurantLongitude}
                  handleInfoUpdate={handleInfoUpdate}
                  userInfo={userInfo}
                />
              </div>
            )}

            {userInfo.userType === "distributionLocation" ? (
              <div label="Communications">
                <Communications
                  userInfo={userInfo}
                  handleInfoUpdate={handleInfoUpdate}
                />
              </div>
            ) : (
              <div label={null}></div>
            )}

            <div label="Order History">
              {(userInfo.userType === "restaurant" ||
                userInfo.userType === "admin") && (
                <div>
                  {recurringOrders.length === 0 ? (
                    <div className="Recurring-Orders">
                      {noRecurringOrders === true ? (
                        <h5>You have no recurring orders</h5>
                      ) : (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() =>
                            LoadRecurringOrders(
                              setNoRecurringOrders,
                              setRecurringOrders,
                            )
                          }
                        >
                          Current recurring orders
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className="Recurring-Orders">
                      <h5>Current Recurring Orders</h5>
                      {recurringOrders.map((recurringOrder, index) => (
                        <Chip
                          color="primary"
                          key={recurringOrder + "-" + index}
                          size="small"
                          style={{ marginRight: "4px" }}
                          label={recurringOrder.ingredient}
                          onDelete={() =>
                            EndRecurringDelivery(
                              recurringOrder,
                              index,
                              recurringOrders,
                              setRecurringOrders,
                            )
                          }
                          icon={
                            <FontAwesomeIcon
                              className="w3-hover-opacity"
                              icon={faClock}
                            />
                          }
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
              {userInfo.userType === "distributionLocation" && (
                <ChangeOrderLogContext.Provider
                  value={{ orderLog, updateOrderLog, updatePaymentHistory }}
                >
                  <PaymentHistoryTotalsDialog
                    orderPaymentHistory={orderPaymentHistory}
                    userInfo={userInfo}
                  />
                </ChangeOrderLogContext.Provider>
              )}

              {orderLog.length !== 0 ? (
                <div>
                  {/* <button onClick = {() => handleCopy()}>handleCopy</button> */}
                  {/* <button onClick = {() => handleOrderCopy()}>handleOrderCopy</button> */}
                  <CommunityOrderChunksContext.Provider
                    value={{
                      communityOrderChunks,
                      setCommunityOrderChunks,
                    }}
                  >
                    <ChangeOrderLogContext.Provider
                      value={{
                        orderLog,
                        updateOrderLog,
                        updatePaymentHistory,
                        leftovers,
                        setLeftovers,
                      }}
                    >
                      {orderLog.map((order, orderIndex) => (
                        <OrderHistory
                          key={orderIndex}
                          order={order}
                          orderAgain={orderAgain}
                          orderIndex={orderIndex}
                          userInfo={userInfo}
                          orderPaymentHistory={orderPaymentHistory}
                        />
                      ))}
                    </ChangeOrderLogContext.Provider>
                  </CommunityOrderChunksContext.Provider>

                  {
                    // Checks if there are any more orders to load
                    lastLoadedOrder != null && (
                      <span className="Load-More-Button">
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={LoadMore}
                        >
                          Load More
                        </Button>
                      </span>
                    )
                  }
                </div>
              ) : (
                <h3> No orders to display! </h3>
              )}
            </div>

            <div label="Community">
              {userInfo.userType === "distributionLocation" && (
                <>
                  <CommunityDistributionLocation userInfo={userInfo} />
                </>
              )}
              {userInfo.userType === "individual" && (
                <>
                  <CommunityIndividual userInfo={userInfo} />
                </>
              )}
              {userInfo.userType === "admin" && (
                <>
                  <CommunityAdmin />
                </>
              )}
            </div>

            {userInfo.userType === "individual" ? (
              <div label="Wallet">
                <IndividualWalletTab userId={userInfo.userId} />
              </div>
            ) : (
              <div label={null}></div>
            )}

            {userInfo.userType === "distributionLocation" ? (
              <div label="Pantry">
                <DisplayPantry
                  userInfo={userInfo}
                  leftovers={Array.isArray(leftovers) ? [...leftovers] : []}
                  setLeftovers={setLeftovers}
                />
              </div>
            ) : (
              <div label={null}></div>
            )}

            {userInfo.userType === "distributionLocation" ? (
              <div label="Farms">
                <FarmTab
                  userInfo={userInfo}
                  handleUserInfoChange={handleUserInfoChange}
                  distributionLocationInformation={
                    distributionLocationInformation
                  }
                  setDistributionLocationInformation={
                    setDistributionLocationInformation
                  }
                />
              </div>
            ) : (
              <div label={null}></div>
            )}
          </Tabs>
          <PlacingOrderDialog open={placingOrder} />
          <CustomizedSnackbars
            message="Your order has been placed"
            open={snackBarOpen}
            handleClose={handleSnackBarClose}
          />
        </div>
        <Footer />
      </div>
    );
  } else {
    return <LoadingContent />;
  }
}
