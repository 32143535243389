// Displays more detailed information about the order cutoffs and includes informtion
// about the farmers cutoffs that might be different than the order cutoff of the distribution
// location itself.
import React, { useState } from "react";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";

export default function ParticipationFeesPopOver({ farmDetails }) {
  // Set the anchor of where to place the popover.
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <HelpIcon
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ color: "red", marginRight: "5px", fontSize: "25px" }}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="left-start"
        sx={{
          zIndex: 1300, // Ensures the popover appears on top of everything
          position: "absolute", // Makes sure it is positioned correctly in the flow
        }}
      >
        <Box
          sx={{
            width: "400px",
            boxShadow:
              "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
            bgcolor: "background.paper",
          }}
        >
          <Typography sx={{ p: 1 }}>
            We charge a participation fee based on the total value of the food,
            which can be paid with either credits or cash. This fee helps fund a
            staff member to fill in for any gaps left by our community
            participants. The value of your order includes the value of free
            rescued food because, although the food itself is free, we still
            need to receive, sort, and distribute it, which requires resources.
            Apply for different roles found on our community page to earn
            credits to cover this fee.
            <br />
          </Typography>
        </Box>
      </Popper>
    </div>
  );
}
