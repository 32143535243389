// The component on the individual basket page that includes the user's volunteer
// fee and delivery fee.
import React, { useEffect, useContext, useState } from "react";
import ParticipationFeesPopOver from "./ParticipationFeesPopover.jsx";
import DeliveryChargePopover from "../../MarketplacePage/Components/DeliveryChargePopover.jsx";
import { NonFoodCostsContext } from "../../Basket.js";

export default function AdditionalFees({
  costTotal,
  pickupLocation,
  suggestedFees,
  packageFeeTotal,
  communityLocation,
  currentStatus,
  deliveryFee,
  userInfo,
}) {
  const { setCashPortion, setCreditPortion } = useContext(NonFoodCostsContext);

  // This is the dollar amount owed for the volunteer fee.  It can be paid using
  // cash or credits.
  const [volunteerDollarAmount, setVolunteerDollarAmount] = useState(0);

  // Calculates volunteer Fee and sets the cash and credits portions to their defaults.
  useEffect(() => {
    // Calculate the total volunteer fee.
    const volunteerDollarAmountTemp =
      ((costTotal + suggestedFees) * pickupLocation.volunteerPercentage) / 100;
    // set the cash portion to the total volunteer amount owed.
    setCashPortion(parseFloat(volunteerDollarAmountTemp).toFixed(2));
    // Set the credit portion to 0.00
    setCreditPortion(parseFloat(0).toFixed(2));
    // set the volunteer amount in the hook.
    setVolunteerDollarAmount(parseFloat(volunteerDollarAmountTemp));
  }, [costTotal, suggestedFees]);

  // The base delivery fee that is shared by all the community members.
  let baseDeliveryFee = 0;
  // If the user has selected a pickupLocation
  if (userInfo.pickupLocation !== undefined) {
    // If the marketplace is for pickup at a community host.
    if (userInfo?.pickupLocation?.pickupLocation) {
      // Set the CommunityHost to the pickup location.
      communityLocation = userInfo.pickupLocation;
      // The base delivery fee that is set by the distribution location.
      baseDeliveryFee = communityLocation.pickupLocation.baseDeliveryFee;
      // If the communityHub has a special delivery fee set it.
      if (
        communityLocation.communityDeliveryFee ||
        communityLocation.communityDeliveryFee === 0
      ) {
        baseDeliveryFee = communityLocation.communityDeliveryFee;
      }

      // Check to see if there any people ordering from this location.  If there
      // is no one yet then we don't want to divide by 0 so we just say it is the maximum
      // delivery fee amount.
      if (currentStatus[1] > 0 && !!userInfo.pickupLocation.pickupLocation) {
        let numberOfSplitting = currentStatus[1];
        // If the current user has not already ordered and they are placing a new
        // order then we want to add them to the total count.
        if (!currentStatus[2]) {
          numberOfSplitting += 1;
        }
        // Set the delivery fee to equal the base fee divided amongst all the users.
        deliveryFee = baseDeliveryFee / numberOfSplitting;
      }
      // If there are no other users then the delivery fee is the base fee.
      else {
        deliveryFee = baseDeliveryFee;
      }
    }
  }

  // Calculates volunteer Fee and sets the cash and credits portions to their defaults.
  useEffect(() => {
    // Calculate the total volunteer fee.
    const volunteerDollarAmountTemp =
      ((costTotal + suggestedFees) * pickupLocation?.volunteerPercentage) / 100;
    // set the volunteer amount in the hook.
    setVolunteerDollarAmount(parseFloat(volunteerDollarAmountTemp));
  }, [costTotal, suggestedFees]);

  return (
    <div>
      {packageFeeTotal > 0 && (
        <p className="Total-Fees">
          Total Package Fees: ${packageFeeTotal.toFixed(2)}
        </p>
      )}

      {!!communityLocation?.pickupLocation && (
        <>
          {currentStatus[2] ? (
            <p className="Total-Fees">
              <s>Delivery Charge:</s>
              <p
                style={{
                  margin: "0px 0px 0px 5px",
                  fontSize: "10px",
                  color: "gray",
                }}
              >
                Adding to existing order
              </p>
            </p>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "end",
                minWidth: "225px",
              }}
            >
              <DeliveryChargePopover
                baseDeliveryFee={baseDeliveryFee.toFixed(2)}
              />
              <p className="Total-Fees">
                Delivery Charge: ${deliveryFee.toFixed(2)}
              </p>
            </div>
          )}
        </>
      )}

      {pickupLocation?.volunteerPercentage !== undefined && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            right: "45px",
            color: "red",
            width: "260px",
          }}
        >
          <ParticipationFeesPopOver />
          <p style={{ margin: "5px 0px" }}></p> Participation Fee{" "}
          {pickupLocation?.volunteerPercentage}% : $
          {volunteerDollarAmount.toFixed(2)}{" "}
        </div>
      )}
    </div>
  );
}
