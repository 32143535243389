// This loads the community wallets.
import React, { useEffect } from "react";
import useFirestore from "./useFirestore";

export default function useCommunityWallets() {
  const database = useFirestore();
  // holds the errors if there are any
  const [error, setError] = React.useState();
  // It is the reverse of most components where false means it's done loading and
  // true means it's currently loading
  const [loading, setLoading] = React.useState(false);
  // Holds the orders of the distribution location
  const [communityWallets, setCommunityWallets] = React.useState([]);

  useEffect(() => {
    // Start loading.
    setLoading(true);
    // Holds all the wallets of the community members.
    const walletTransfer = [];

    // Set the doc reference to the Users -> userId -> Orders collection
    const walletsDocRef = database.collection("CommunityWallets");

    walletsDocRef
      .get()
      .then((walletCollection) => {
        walletCollection.docs.forEach((walletDoc) => {
          if (walletDoc.exists) {
            // Adds the wallet.
            walletTransfer.push(walletDoc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            setLoading(false);
          }
        });
        // Sets the wallets.
        setCommunityWallets([...walletTransfer]);
        setLoading(false);
      })
      .catch(function (error) {
        setError("Error getting document:", error);
        setLoading(false);
      });
  }, [database]);

  // return the error, communityWallets dictionary and the loading
  return [communityWallets, loading, error];
}
