// This is used by admin users to display their community options.
import React, { useEffect, useState } from "react";
import firebase from "../../../components/Firebase.js";
import LoadingContent from "../../../components/LoadingContent.jsx";
import AddVolunteerJobDialog from "../Dialogs/VolunteerSchedule/AddVolunteerJobDialog.jsx";
import EditVolunteerJobDialog from "../Dialogs/VolunteerSchedule/EditVolunteerJobDialog.jsx";
import Tabs from "../../../components/Tabs.js";
import VolunteerScheduleTab from "./VolunteerScheduleTab.jsx";
import CommunityWalletsTab from "./CommunityWalletsTab.jsx";

// The community tab for admin users.
export default function CommunityAdmin({ userInfo }) {
  // Set up the database with firebase.
  const database = firebase.firestore();
  // The volunteerJobs that are used to display on the community page.
  const [volunteerJobs, setVolunteerJobs] = useState([]);

  // This is set to false until the data from the database has been loaded
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Load the volunteer collection.
    const volunteerDocRef = database
      .collection("Volunteer")
      .where("status", "==", true);

    const volunteerDataTransfer = [];

    // Cycle through all the found documents and add them to the volunteerJobs hook.
    volunteerDocRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          if (doc.exists) {
            volunteerDataTransfer.push(doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
        setVolunteerJobs(volunteerDataTransfer);
        setLoading(true);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  // If it is done loading then display the volunteer positions otherwise display
  // the inline loading.
  if (loading) {
    // If there are no volunteer positions then display the add button and tell them
    // that there are none.
    if (volunteerJobs.length === 0) {
      return (
        <div>
          <AddVolunteerJobDialog
            volunteerJobs={volunteerJobs}
            setVolunteerJobs={setVolunteerJobs}
          />
          <h1>There are currently no volunteer opportunities available.</h1>
        </div>
      );
    }
    // Otherwise if there are volunteer positions display them.
    else {
      return (
        <div className="Tab-Box">
          <Tabs>
            <div label="Volunteer Positions" style={{ margin: "16px" }}>
              <AddVolunteerJobDialog
                volunteerJobs={volunteerJobs}
                setVolunteerJobs={setVolunteerJobs}
              />
              {volunteerJobs.map((volunteerPosition, index) => (
                <EditVolunteerJobDialog
                  key={index}
                  volunteerPosition={volunteerPosition}
                  index={index}
                  volunteerJobs={volunteerJobs}
                  setVolunteerJobs={setVolunteerJobs}
                />
              ))}
            </div>
            <div label="Volunteer Schedule" style={{ margin: "16px" }}>
              <VolunteerScheduleTab volunteerJobs={volunteerJobs} />
            </div>
            <div label="Community Wallets" style={{ margin: "16px" }}>
              <CommunityWalletsTab />
            </div>
          </Tabs>
        </div>
      );
    }
  } else {
    return (
      <div>
        <LoadingContent inline={true} />
      </div>
    );
  }
}
