// This is called by the PlaceOrder function after the program has checked that
// the user hasn't ordered any items that are farm limited and are no longer available.
// If this function or any of it's children do fail then those items will be restored.
// Depending on what kind of user is placing the order different functions will be called
// to complete the order, update the database and send on emails.
import UpdateIngredientList from "./UpdateIngredientList.js";
import YYYYMMDDConverter from "./YYYYMMDDConverter.js";
import IndividualOrder from "./IndividualOrder.js";
import DistributionLocationOrder from "./DistributionLocationOrder.js";
import FinalisePlaceOrder from "./FinalisePlaceOrder.js";

export default function FarmLimitsCompletePlacingOrder(
  donationSubsidy,
  farmerInfo,
  userInfo,
  foodItems,
  batch,
  database,
  firebase,
  contactMethods,
  customerContribution,
  setFoodItems,
  costTotal,
  grandTotal,
  setPlacingOrder,
  setPlacingBatchesOrder,
  setSnackBarOpen,
  handleUserInfoChange,
  basketOrder,
  checkList,
  selectedDate,
  importedOrder,
  communityOrders,
  addToExistingOrder,
  modifiedUserPickups,
  farmTotals,
  setCommunityFoodItems,
  pantryFoodItems,
  setPantryFoodItems,
  setAddingToPantry,
  setImportedAddingToPantry,
  foodItemsUpdated,
  marketplaceMessage,
  marketplaceMessageEnabled,
  deliveryFee,
  cashPortion,
  creditPortion,
  extraQuantitiesDict,
  paymentMethod,
) {
  // Used to find the next order cutoff of a farm.
  const today = new Date();
  const orderCutoffDay = 0;
  const daysOfTheWeek = {
    Mon: 1,
    Tues: 2,
    Wed: 3,
    Thurs: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };
  // This array is used to reflect the dropOff day of a recurring order
  const daysOfTheWeekArray = [
    null,
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const futureDate = new Date();
  futureDate.setDate(new Date().getDate() + 14);
  // Guaruntee that donationSubsidy is a number and not a string
  donationSubsidy = parseFloat(donationSubsidy);

  // Creates a list of the farms with no repeats
  const noRepeatsFarmerInfo = [];
  farmerInfo.forEach((element) => {
    const result = noRepeatsFarmerInfo.filter(
      (arr) => arr.farmName === element.farmName,
    );
    if (result.length === 0) {
      noRepeatsFarmerInfo.push(element);
    }
  });

  // This dictionary will store each farms next deliveryDay in a date format {farmName : date}
  let farmDeliveryDays = {};

  // Write to the Order collection in the database
  const now = YYYYMMDDConverter();
  const ordersDocRef = database
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("Orders")
    .doc(now);

  // If the user is ordering and picking up through a pickup location than it only
  // matters when the distribution location's cutoff and pickup are not the farms
  // therefore only other user groups will have to find out when the farms deliver.
  // The individual user also doesn't need to update the ingredientList since
  // the distribution Location will do that anyway.
  if (userInfo.userType !== "individual") {
    farmDeliveryDays = UpdateIngredientList(
      noRepeatsFarmerInfo,
      futureDate,
      today,
      daysOfTheWeek,
      orderCutoffDay,
      foodItems,
      daysOfTheWeekArray,
      userInfo,
      batch,
    );
  }

  if (userInfo.userType === "individual") {
    IndividualOrder(
      today,
      daysOfTheWeek,
      userInfo,
      foodItems,
      farmerInfo,
      contactMethods,
      donationSubsidy,
      customerContribution,
      batch,
      ordersDocRef,
      now,
      setFoodItems,
      costTotal,
      grandTotal,
      setPlacingOrder,
      setSnackBarOpen,
      handleUserInfoChange,
      basketOrder,
      foodItemsUpdated,
      deliveryFee,
      cashPortion,
      creditPortion,
      paymentMethod,
    );
  } else if (userInfo.userType === "distributionLocation") {
    DistributionLocationOrder(
      userInfo,
      checkList,
      selectedDate,
      importedOrder,
      foodItems,
      communityOrders,
      ordersDocRef,
      now,
      farmerInfo,
      contactMethods,
      batch,
      addToExistingOrder,
      handleUserInfoChange,
      modifiedUserPickups,
      noRepeatsFarmerInfo,
      farmDeliveryDays,
      farmTotals,
      donationSubsidy,
      customerContribution,
      setPlacingOrder,
      setPlacingBatchesOrder,
      setSnackBarOpen,
      setFoodItems,
      setCommunityFoodItems,
      pantryFoodItems,
      setPantryFoodItems,
      setAddingToPantry,
      setImportedAddingToPantry,
      foodItemsUpdated,
      marketplaceMessage,
      marketplaceMessageEnabled,
      extraQuantitiesDict,
    );
  } else {
    batch.set(ordersDocRef, {
      orderDate: now,
      foodList: foodItems,
      farmerList: farmerInfo,
      contactMethods,
    });
    // Testing Remove
    // Send emails to any users that had their orders modified, send orders to farmers
    // and update the database.
    FinalisePlaceOrder(
      foodItems,
      importedOrder,
      communityOrders,
      userInfo,
      checkList,
      handleUserInfoChange,
      batch,
      modifiedUserPickups,
      noRepeatsFarmerInfo,
      farmDeliveryDays,
      contactMethods,
      foodItems,
      farmTotals,
      donationSubsidy,
      customerContribution,
      setPlacingOrder,
      setPlacingBatchesOrder,
      setSnackBarOpen,
      setFoodItems,
      setCommunityFoodItems,
      true,
      setAddingToPantry,
      setImportedAddingToPantry,
      setPantryFoodItems,
      {},
      [],
    );
  }

  // return the order to the function caller.  This is only really relevant for
  // when a user is placing an order from the MyAccount page.  Since some users
  // can't place an order again then this was left to be deprecated for them.
  if (userInfo.userType === "individual") {
    return {
      orderDate: now,
      foodList: foodItems,
      farmerList: farmerInfo,
      contactMethods,
      donationSubsidy,
      customerContribution,
      pickupLocation: userInfo.pickupLocation,
    };
  } else {
    return {
      orderDate: now,
      foodList: foodItems,
      farmerList: farmerInfo,
      contactMethods,
    };
  }
}
