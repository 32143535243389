import React from "react";
import { Grid } from "@material-ui/core";
import FindNextDay from "../../../functions/FindNextDay.js";
import YYYYMMDDConverter from "../../../functions/YYYYMMDDConverter.js";

const UserPaymentByDateDistributionLocation = ({
  paymentDetails,
  sortedCommunityOrderByDistributionLocation,
  moneyDepositDate,
}) => {
  // Initialize paymentTable as a nested dictionary
  const paymentTable = {};

  // Loop through the sorted community orders
  sortedCommunityOrderByDistributionLocation.forEach((communityOrder) => {
    // Loop through deposits to accumulate amounts based on the payment method
    const deposits =
      paymentDetails.communityOrders[communityOrder]?.deposits || [];
    deposits.forEach((deposit) => {
      // Check the date of the deposit.
      let depositDate = new Date(deposit.date);
      // Find the next Saturday after the deposit.
      depositDate = FindNextDay(depositDate, 6);
      // Convert to our YYYYMMDD format.
      const depositDateYYYYMMDD = YYYYMMDDConverter(depositDate);
      // Check if the deposit date was the same as the transaction we are looking at.
      // If it is then we want to include it otherwise ignore it.
      if (depositDateYYYYMMDD === moneyDepositDate) {
        // Retrieve community location from payment details
        const communityLocation =
          paymentDetails.communityOrders[communityOrder]?.communityLocation ||
          "Onsite";
        const userSplit = communityOrder.split("-");
        const rawDate = userSplit[3];

        // Format the date as YYYY-MM-DD
        const distributionDate =
          rawDate && rawDate.length === 8
            ? `${rawDate.substring(0, 4)}-${rawDate.substring(
                4,
                6,
              )}-${rawDate.substring(6, 8)}`
            : "Unknown Date";

        // Ensure the date exists in the paymentTable
        if (!paymentTable[distributionDate]) {
          paymentTable[distributionDate] = {};
        }

        // Ensure the location exists under the date
        if (!paymentTable[distributionDate][communityLocation]) {
          paymentTable[distributionDate][communityLocation] = {
            eTransfer: 0,
            cash: 0,
            F2PMCredits: 0,
          };
        }

        const method = deposit.method || "Unknown Method";
        const amount = deposit.amount || 0;

        if (method === "ETransfer") {
          paymentTable[distributionDate][communityLocation].eTransfer += amount;
        } else if (method === "Cash") {
          paymentTable[distributionDate][communityLocation].cash += amount;
        } else if (method === "F2PMCredits") {
          paymentTable[distributionDate][communityLocation].F2PMCredits +=
            amount;
        }
      }
    });
  });

  // Convert paymentTable into a renderable format
  const renderableData = [];
  for (const distributionDate in paymentTable) {
    for (const communityLocation in paymentTable[distributionDate]) {
      const { eTransfer, cash, F2PMCredits } =
        paymentTable[distributionDate][communityLocation];
      renderableData.push({
        distributionDate,
        communityLocation,
        eTransfer,
        cash,
        F2PMCredits,
      });
    }
  }

  if (renderableData.length === 0) {
    return <div>No payment data available for this distribution date.</div>;
  }

  return (
    <div style={{ marginBottom: "5px" }}>
      {/* Display payment data */}
      {renderableData.map(
        ({
          distributionDate,
          communityLocation,
          eTransfer,
          cash,
          F2PMCredits,
        }) => (
          <Grid
            container
            key={`${distributionDate}-${communityLocation}`}
            direction="row"
            alignItems="center"
            spacing={1}
            style={{
              textAlign: "center",
              marginBottom: "5px",
              borderBottom: "1px solid #ddd", // Horizontal line
            }}
          >
            <Grid item xs>
              {distributionDate}
            </Grid>
            <Grid item xs>
              {communityLocation}
            </Grid>
            <Grid item xs>
              ${cash.toFixed(2)}
            </Grid>
            <Grid item xs>
              ${eTransfer.toFixed(2)}
            </Grid>
            <Grid item xs>
              ${F2PMCredits.toFixed(2)}
            </Grid>
          </Grid>
        ),
      )}
    </div>
  );
};

export default UserPaymentByDateDistributionLocation;
