// We need to create the payment data for all the users.
// This is typically done when a distribution location places
// an order with the farmers.
export default function CreatePaymentData(
  createNew,
  paymentData,
  orderCost,
  userOrderTime,
  distributionlocation,
  paymentMethod,
) {
  // Loads the existing payment data.
  const paymentDataTemp = { ...paymentData };
  // If the user has already placed an order then we are just updating their order
  // otherwise create a new order history for them.
  if (!createNew) {
    // Add the new orders amount to their old amount.
    paymentDataTemp.totalOwed += orderCost;
    // Add this new order's total and date to the orderHistory.
    paymentDataTemp.orderHistory[userOrderTime] = paymentDataTemp.totalOwed;
    // Update the notes to inlcude the additional order added.
    paymentDataTemp.orderNotes.concat(
      `Added additional order on ${userOrderTime}. `,
    );
    paymentDataTemp.paymentMethod.push({ [paymentMethod]: orderCost });
  } else {
    // Create an empty deposit array for when the user eventually pays.
    paymentDataTemp.deposits = [];
    // The total amount the user owes for their order.
    paymentDataTemp.totalOwed = orderCost;
    // Include the distribution location's name their ordering from.
    paymentDataTemp.distributionLocation = distributionlocation;
    // Add to the order history to include the timestamp of the order and the cost.
    paymentDataTemp.orderHistory = {
      [userOrderTime]: orderCost,
    };
    // Create a blank order notes.
    paymentDataTemp.orderNotes = "";
    // Create an array for if the user needs refunds in the future.
    paymentDataTemp.orderRefunds = [];
    paymentDataTemp.paymentMethod = [{ [paymentMethod]: orderCost }];
  }

  return paymentDataTemp;
}
