import React from "react";
import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import WeekPicker from "./WeekPicker";
import dayjs, { type Dayjs } from "dayjs";
import OrderCard from "../OrderCard";
import useCommunityOrder from "../../../functions/Firestore/useCommunityOrder";
import toThursday from "./toThursday";
import useDistributionLocations from "../../../functions/Firestore/useDistributionLocations";

const ItemContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(1),
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function OrderDashboard({ date }: { date: Dayjs }) {
  // WHY is the format MM-DD-YYYY?!?
  const { order, initialized } = useCommunityOrder(date.format("MM-DD-YYYY"));
  const { locationDetails } = useDistributionLocations();
  const locationInfo = (
    locationDetails as { userId: string; name: string }[]
  ).find((v: { userId: string }) => v.userId === order?.owner);

  if (!initialized) return "...loading";
  if (!order) return "no order found";
  if (!locationInfo?.name) return "no location found";
  return <OrderCard order={order} location={locationInfo.name} />;
}

export function OrderDetails() {
  const [orderDate, setOrderDate] = React.useState<Dayjs>(toThursday(dayjs()));
  return (
    <ItemContainer>
      <Item variant="outlined">
        <Typography variant="body1" paragraph>
          In order to achieve complete transparency this tab provides
          information about what a distribution site ordered from a farm along
          with the orders of individual customers. If you would like more
          information please contact{" "}
          <a href={"mailto:anthony@farmtoplatemarketplace.com"}>
            anthony@farmtoplatemarketplace.com
          </a>
          .
        </Typography>
        <Typography variant="body1">
          The data is accurate at the time the order is placed by the
          distribution location and may not include changes after that, such as,
          same day purchases, price changes from farmer, or larger than expected
          donations.
        </Typography>
      </Item>
      <Item variant="outlined">
        <WeekPicker date={orderDate} onChange={(d: Dayjs) => setOrderDate(d)} />
      </Item>
      {orderDate != null && (
        <Item variant="outlined">
          <OrderDashboard date={orderDate} />
        </Item>
      )}
    </ItemContainer>
  );
}
