// This function calculates how the payment will change and if there are
// any credits that need to be deducted from their account.  It will add up
// any deposits and refunds and then also check if a deposit has been changed
// from one payment to F2PM credits or vice versa.
export default function F2PMCreditsAdjustment(
  userPaymentHistory,
  deposits,
  refunds,
) {
  // The new balance of their credits being used for this order.
  let totalNewBalance = 0;
  // The old balance of their credits being used for this order.
  let totalOldBalance = 0;
  // The new total for refunds for this order.
  let totalNewRefunds = 0;
  // The old total for refunds for this order.
  let totalOldRefunds = 0;
  // Cycle through the deposits.
  deposits.forEach((deposit) => {
    // If the new deposits are credits.
    if (deposit.method === "F2PMCredits") {
      // Add the balance of credits being used to pay the order.
      totalNewBalance += parseFloat(deposit.amount);
    }
  });
  // Cycle through the old deposits for this order.
  userPaymentHistory.deposits.forEach((deposit) => {
    if (deposit.method === "F2PMCredits") {
      // Add the balance of credits was paying the order.
      totalOldBalance += parseFloat(deposit.amount);
    }
  });
  // This the change to the credits paying the order.
  let totalBalanceChange = totalOldBalance - totalNewBalance;

  // Cycle through the refunds.
  refunds.forEach((refund) => {
    // Total up the refunds now.
    totalNewRefunds += parseFloat(refund.amount);
  });
  // Cycle through the old refunds for this order.
  userPaymentHistory.orderRefunds.forEach((refund) => {
    // Total up the refunds from before.
    totalOldRefunds += parseFloat(refund.amount);
  });
  // See the change in refunds from before and now.
  const totalRefunds = totalNewRefunds - totalOldRefunds;
  // Add the change from refunds back to the total balance change.
  totalBalanceChange += totalRefunds;

  return totalBalanceChange;
}
