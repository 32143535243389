// This a dialog shows a user's transaction history out of their wallet.
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function TransactionHistoryDialog({
  buttonText,
  buttonSize,
  transactionHistory,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Ensure transactionHistory is sorted in descending order (latest first)
  const sortedTransactions = [...transactionHistory].sort(
    (a, b) => new Date(b.date) - new Date(a.date),
  );

  return (
    <div>
      <Button
        size={buttonSize}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Transaction History</DialogTitle>
        <DialogContent>
          {sortedTransactions.length === 0 ? (
            <p>No Transactions to display.</p>
          ) : (
            <table border="1">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Credit or Debit</th>
                  <th>Amount</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {sortedTransactions.map((transaction, index) => (
                  <tr key={index}>
                    <td>{transaction.date}</td>
                    <td>{transaction.transactionType}</td>
                    <td>${transaction.amount?.toFixed(2) || "0.00"}</td>
                    <td> {transaction.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              CLOSE
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
