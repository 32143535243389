// This is to create a user's wallet.
import firebase from "../../../components/Firebase.js";

export default function CreateUserWallet(
  firstName,
  lastName,
  userId,
  amount,
  transaction,
) {
  const database = firebase.firestore();
  const batch = database.batch();
  // Sets their balance to 0.
  let amountTemp = 0.0;
  // Creates a blank transaction history.
  const descriptionTemp = [];
  // If there is an amount being sent in then we have to update
  // the values.
  if (amount !== undefined) {
    amountTemp = amount;
    descriptionTemp.push(transaction);
  }
  // Creates the new wallet with the data.
  const newWallet = {
    firstName,
    lastName,
    userId,
    Total: amountTemp,
    transactionHistory: descriptionTemp,
  };
  // Update the document in the database.
  const docRef = database.collection("CommunityWallets").doc(userId);

  // Update the database.
  batch.set(docRef, { ...newWallet }, { merge: true });

  batch.commit();
  return newWallet;
}
