// This is the donation subsidy radio group that a user uses to choose how
// they would like to add or subtract from their order.
import React, { useState } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import SimplePopover from "../../../components/userInputs/SimplePopover.js";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import UpdateDonationSubsidy from "../Functions/UserInputs/UpdateDonationSubsidy.js";

export default function DonationSubsidyRadioGroup({
  subsidyAmount,
  customerContribution,
  setCustomerContribution,
  donationSubsidy,
  setDonationSubsidy,
  costTotal,
  packageFeeTotal,
  deliveryFee,
  cashPortion,
  subsidyExplanation,
  dollarColour,
  textFieldColour,
}) {
  // This is used to indicate when max subsidy line needs to shake to indicate to
  // the user that they are trying to go over the allowed amount.  It changes the className
  // to trigger the shake that lasts for 1 second.  When it is nothing than it has no class.
  const [shake, setShake] = useState("nothing");

  return (
    <FormControl style={{ marginTop: "10px" }}>
      {/* Reset the value of the donationSubsidy to 0 when the user
            changes to the subsidy radioButton.  This is to prevent mistakenly
            going into the negative if the value is bigger than what is owed */}
      <FormLabel className={shake}>
        Optional: (Max Subsidy is ${subsidyAmount.toFixed(2)})
      </FormLabel>
      <span className="Donation-Subsidy-Radio-Group">
        <RadioGroup
          value={customerContribution}
          onChange={(event) => {
            setDonationSubsidy(0);
            setCustomerContribution(event.target.value);
          }}
        >
          <span className="Info-And-Radio-Button">
            <FormControlLabel
              value="donation"
              control={<Radio />}
              label="Donation"
            />
            <SimplePopover content="Donations are collected to provide subsidies for others to buy food.  100% of the donations are used to directly subsidize guests that cannot afford to pay full price." />
          </span>
          <span className="Info-And-Radio-Button">
            <FormControlLabel
              value="subsidy"
              control={<Radio />}
              label="Subsidy"
            />
            <SimplePopover content={subsidyExplanation} />
          </span>
        </RadioGroup>
        <p
          className="Donation-Subsidy-Text-Field"
          style={{ color: dollarColour, marginRight: "5px" }}
        >
          : $
        </p>
        <TextField
          value={donationSubsidy}
          style={{ width: "100px" }}
          color={textFieldColour}
          type="number"
          variant="outlined"
          onChange={(e) => {
            UpdateDonationSubsidy(
              e.target.value,
              customerContribution,
              setDonationSubsidy,
              costTotal,
              setShake,
              packageFeeTotal,
              deliveryFee,
              cashPortion,
              subsidyAmount,
            );
          }}
        />
      </span>
    </FormControl>
  );
}
