// This is called by the confirm order dialog and decides which message to display
// to the user to confirm their order
import React from "react";

export default function ConfirmOrderMessages(
  messageType,
  userInfo,
  grandTotal,
  paymentMethod,
) {
  let distributionLocation = userInfo.pickupLocation;
  // Check if this is a community host.
  if (distributionLocation.pickupLocation !== undefined) {
    distributionLocation = distributionLocation.pickupLocation;
  }
  const distributionLocationEmail = distributionLocation.etransferEmail;

  // This is so that when a user orders from a distribution location it offers cash
  // but if it is a community location it is etransfer only.
  let cashOrEtransfer = `Please send an e-transfer to ${distributionLocationEmail} `;

  if (paymentMethod === "cash") {
    cashOrEtransfer = "Please bring cash on pickup ";
  }

  // Checks to see if the user is supposed to receive the normal message and if the
  // distribution location they're using has auto-deposit set up.  If it does then
  // switch to a different message.
  if (messageType === "normalMessage") {
    messageType = "normalMessageAutoDeposit";
  }
  // Checks to see if the user is supposed to receive the subsidy Owing Messge message and if the
  // distribution location they're using has auto-deposit set up.  If it does then
  // switch to a different message.
  if (messageType === "subsidyOwingMessge") {
    messageType = "subsidyOwingMessgeAutoDeposit";
  }
  const messages = {
    // the message if the user is selected donation and the distribution location has etransfer autodeposit.
    normalMessageAutoDeposit: (
      <>
        {cashOrEtransfer}
        for payment.
      </>
    ),
    // If the user selected subsidy but left the amount at 0
    subsidyZeroMessage: (
      <>
        You have selected to use a subsidy but you left the amount at "$0". If
        you need a subsidy please select an amount. If you would like to set the
        donation amount to $0 do that instead.
        <br />
        <br />
        This is to clarify the use of the subsidy and ensure people who need
        help receive it.
      </>
    ),
    // If the user selected subsidy but still owes something and the distribution location has etransfer autodeposit.
    subsidyOwingMessgeAutoDeposit: (
      <>
        Please note that there is still an amount owed. You cannot use a future
        subsidy to pay this amount. {cashOrEtransfer}
        for a total of <b>${grandTotal.toFixed(2)}</b>.
      </>
    ),
  };

  return messages[messageType];
}
