// This shows the balance of a user's wallet and will pull the data needed
// to dislay.

import React from "react";
import LoadingContent from "../../LoadingContent.jsx";
import useIndividualWallet from "../../../functions/Firestore/useIndividualWallet.js";
import TransactionHistoryDialog from "../../../pages/MyAccountPage/Dialogs/TransactionHistoryDialog.jsx";

export default function WalletBalance({
  communityWallet,
  setCommunityWallet,
  userId,
}) {
  const [loading, setLoading] = React.useState(true);
  // Makes a call to the database to load the balance.
  const error = useIndividualWallet(userId, setCommunityWallet, setLoading);

  // While loading the balance.
  if (loading) {
    return (
      <div>
        <LoadingContent inline={true} />
      </div>
    );
  }
  // Once it is loaded.
  else {
    // If there was an error display the error.
    if (error) {
      return (
        <div>
          <p>Error loading volunteer schedule: {error}</p>
        </div>
      );
    }
    // Shows the balance of the wallet.
    return (
      <div>
        <h4>F2PM Wallet</h4>
        <table border="1">
          <thead>
            <tr>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TransactionHistoryDialog
                  buttonSize="large"
                  buttonText={`$${
                    communityWallet?.Total
                      ? communityWallet.Total.toFixed(2)
                      : "0.00"
                  }`}
                  transactionHistory={communityWallet?.transactionHistory || []}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
