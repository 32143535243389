// This a dialog is used to adjust a community member's wallet.  It shows a
// textfield that the user can adjust to make the necessary adjustment.
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import "date-fns";
import CommunityMemberTransaction from "../../../functions/Firestore/Wallet/CommunityMemberTransaction.js";

export default function AdjustMemberWallet({
  communityWallets,
  index,
  setCommunityWallets,
}) {
  const [open, setOpen] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // When the user saves their changes then this calls the functions to save to
  // the database.
  const handleSave = async (e) => {
    e.preventDefault();
    const communityWalletsTemp = communityWallets;
    const updatedWallet = await CommunityMemberTransaction(
      transactionAmount,
      { ...communityWallets[index] },
      "Admin User Adjustment",
    );
    communityWalletsTemp.push(updatedWallet);
    setCommunityWallets([...communityWalletsTemp]);
    setOpen(false);
  };

  return (
    <div>
      <Button size="small" color="primary" onClick={handleClickOpen}>
        ADJUST WALLET
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          ADD OR SUBTRACT AMOUNTS TO THE MEMBER'S WALLET
        </DialogTitle>
        {/* Reset the value of the donationSubsidy to 0 when the user
          changes to the subsidy radioButton.  This is to prevent mistakenly
          going into the negative if the value is bigger than what is owed */}
        <span className="Radio-TextField">
          <p>$</p>
          {
            <TextField
              value={transactionAmount}
              style={{ maxWidth: "60px" }}
              type="number"
              onChange={(e) => {
                setTransactionAmount(parseFloat(e.target.value));
              }}
            />
          }
        </span>

        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          {console.log("transactionAmount is", transactionAmount)}
          {(transactionAmount > 0 || transactionAmount < 0) && (
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
