// This function is used to place the order.  This will write to both the ingredientList
// and the Orders in the database.
import firebase from "../components/Firebase.js";
import AggregateCustomerList from "./AggregateOrder.js";
import FarmLimitsCompletePlacingOrder from "../pages/BasketPage/Functions/FarmLimitsCompletePlacingOrder.js";
import OrderIncompleteRestoreItems from "../pages/BasketPage/Functions/OrderIncompleteRestoreItems.js";
import Swal from "sweetalert2";

// When the order is placed then the basket has to write this to the datebase
// First it will write this information to IngredientList in the datatbase.
// This is what the menu page uses to give customers information about the
// ingredients. There needs to be information in the database about these
// farms otherwise it won't work.
// The second write is to the Users -> Orders collection in the database.  This
// will be used on the MyAccount page to display the users orders
export default function PlaceOrder(
  farmerInfo,
  foodItems,
  userInfo,
  contactMethods,
  donationSubsidy,
  customerContribution,
  handleUserInfoChange,
  basketOrder,
  communityOrders,
  selectedDate,
  costTotal,
  grandTotal,
  importedOrder,
  setPlacingOrder,
  setPlacingBatchesOrder,
  setSnackBarOpen,
  checkList,
  farmTotals,
  modifiedUserPickups,
  setFoodItems,
  setCommunityFoodItems,
  addToExistingOrder,
  pantryFoodItems,
  setPantryFoodItems,
  setAddingToPantry,
  setImportedAddingToPantry,
  marketplaceMessage,
  marketplaceMessageEnabled,
  deliveryFee,
  cashPortion,
  creditPortion,
  extraQuantitiesDict,
  paymentMethod,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // A dictionary with the combined community and imported orders
  let communityAndImportedOrder = {};

  // The list of food that the community ordered.
  let customerFoodList = [];

  // If the user is a distribution location we want to see what the community ordered
  // and put it in customerFoodList.
  if (userInfo.userType === "distributionLocation") {
    // Checks to see if the imported Order exists and if it does then combine the
    // community orders of the current distribution location and the imported one.
    if (Object.keys(importedOrder).length === 0) {
      communityAndImportedOrder = communityOrders;
    } else {
      // Start with adding the communityOrders of the distribution location itself.
      communityAndImportedOrder = communityOrders;
      // Cycle through all the distribution locations imported by this order and
      // add their community orders to the communityAndImportedOrder
      Object.keys(importedOrder).forEach((location) => {
        communityAndImportedOrder = Object.assign(
          {},
          communityAndImportedOrder,
          importedOrder[location].communityOrders,
        );
      });
    }

    // Aggregate the customer List to create one consolidated list of food.
    customerFoodList = AggregateCustomerList(
      communityAndImportedOrder,
      null,
      null,
      null,
      null,
      true,
    )[2];
  }

  // The items that were ordered that have limits set by the farmers.
  const farmLimitedItems = [];

  // Cycle through the foodItems and find ones that have a farmLimit but don't have
  // a limit from the pantry.  If they have a pantry limit they aren't considered
  // limited by the farmer anymore.  This list will be used to update the database.
  foodItems.forEach((foodItem) => {
    if (
      foodItem.farmLimit !== "" &&
      foodItem.farmLimit !== undefined &&
      (foodItem.limit === "" || foodItem.limit === undefined)
    ) {
      farmLimitedItems.push(foodItem);
    }
  });

  // The number of foodItems that have had their farmLimit updated
  let completedUpdates = 0;
  // A dictionary of the documentName of the FoodItem updated and the quantity of
  // the item that was deducted.
  const foodItemsUpdated = {};
  // Cancel holds the string that will be returned if there is not enough
  // of an item when placing the order.
  let cancel = "";

  // If there are no items limited by the farmers then just move on to the next part
  // of placing the order.
  if (farmLimitedItems.length === 0) {
    FarmLimitsCompletePlacingOrder(
      donationSubsidy,
      farmerInfo,
      userInfo,
      foodItems,
      batch,
      database,
      firebase,
      contactMethods,
      customerContribution,
      setFoodItems,
      costTotal,
      grandTotal,
      setPlacingOrder,
      setPlacingBatchesOrder,
      setSnackBarOpen,
      handleUserInfoChange,
      basketOrder,
      checkList,
      selectedDate,
      importedOrder,
      communityOrders,
      addToExistingOrder,
      modifiedUserPickups,
      farmTotals,
      setCommunityFoodItems,
      pantryFoodItems,
      setPantryFoodItems,
      setAddingToPantry,
      setImportedAddingToPantry,
      {},
      marketplaceMessage,
      marketplaceMessageEnabled,
      deliveryFee,
      cashPortion,
      creditPortion,
      {},
      paymentMethod,
    );
  }
  // If there are items that are farm limited.
  else {
    // Cycle through all the foodItems that are limited.
    farmLimitedItems.forEach((foodItem) => {
      // Find the document name in FoodItems in the database.
      const documentName = [
        foodItem.item,
        foodItem.description,
        foodItem.farmName,
      ]
        .join("")
        .replace(/\s/g, "");

      // The quantity of the products that were already deducted by the customer's
      // orders.  Only used by distribution locations.
      let alreadyDeducted = 0;

      // If user is a distribution location then we need to find out how much of the
      // items in the current order were already deducted and don't need to be again.
      if (userInfo.userType === "distributionLocation") {
        // Find the item in the customer food list.
        const index = customerFoodList.findIndex((customerFoodItem) => {
          return (
            customerFoodItem.item === foodItem.item &&
            customerFoodItem.individualDescription ===
              foodItem.individualDescription &&
            customerFoodItem.farmName === foodItem.farmName
          );
        });

        // If the index exists then we need to mark down how much of an item was bought
        // by the community.  The amount will be the individual quantity which is what is
        // used for the farmLimit as well.
        if (index !== -1) {
          alreadyDeducted = parseFloat(customerFoodList[index].quantity);
        }
      }

      // Create a document reference to the foodItem being updated.
      const foodItemsDocRef = database
        .collection("FoodItems")
        .doc(documentName);
      // We run a transaction as we want to make sure that the user is reading the
      // most up to date data from the document we want to make sure that if another
      // user took an item that there is still enough of this foodItem
      return database
        .runTransaction((transaction) => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(foodItemsDocRef).then((foodItemDoc) => {
            if (!foodItemDoc.exists) {
              // eslint-disable-next-line no-throw-literal
              throw "Document does not exist!";
            }
            // Create an updatedFoodItem document that we will use to update the
            // database with the changes made.
            const updatedFoodItem = { ...foodItemDoc.data() };
            // The newLimit is the amount of the item still left
            // after the user takes their amount from it.
            let newLimit = 0;
            // The limitMax is how much is left in the farmLimit of this item and
            // therefore the maximum the user can order.
            let limitMax = 0;

            // The amount that is going to be deducted.  The individual quantity is
            // used here.  If it is a distribution location it will be updated below.
            let deductedQuantity = foodItem.quantity;

            // Calculate the newLimit after the user's order is subtracted from
            // the current farmLimit on the item.
            if (userInfo.userType === "individual") {
              // Simply the farmLimit minus the quantity ordered.
              newLimit = foodItemDoc.data().farmLimit - foodItem.quantity;
              // Only deduct the amount that is guarateed so if they ordered more
              // than that we'll have to adjust it.
              if (
                foodItem.quantity > 0 &&
                foodItem.guaranteedMaxQuantity !== "" &&
                foodItem.quantity > foodItem.guaranteedMaxQuantity
              ) {
                deductedQuantity = foodItem.guaranteedMaxQuantity;
              }
            }
            // If the user is a distribution location then we need to update the quantity
            // to match the farmLimit amount which is in individual quantities.
            else {
              // Since the distributionLocation is importing orders from the community
              // they will already have deducted their portion from the database and therefore we
              // don't want to deduct that amount again so we add that back.
              newLimit =
                foodItemDoc.data().farmLimit -
                (foodItem.quantity * foodItem.distributionQuantity) /
                  foodItem.individualQuantity +
                alreadyDeducted;
              // Again we don't want the already deducted amounts from the community be included
              // here so we will subtract it from the total deducted.
              deductedQuantity =
                (foodItem.quantity * foodItem.distributionQuantity) /
                  foodItem.individualQuantity -
                alreadyDeducted;
            }
            // Make sure the limit isn't less than zero.  If it is then we'll
            // have to end the order and notify the user to update their quantities.
            if (newLimit < 0) {
              // The limit is just whatever is left in the database.
              limitMax = foodItemDoc.data().farmLimit;
              // Create a string to send to the user and reject this transaction.  If isn't empty
              // then we know that another item was also over the max.
              if (cancel !== "") {
                cancel = cancel.concat(
                  limitMax + " " + foodItem.item + " remaining.\n",
                );
              }
              // Create the full message for a first foodItem that is over the limit.
              else {
                cancel =
                  "You ordered more than what is available on the following item(s): \n" +
                  limitMax +
                  " " +
                  foodItem.item +
                  " remaining. \n";
              }
            }
            // If there are enough items in the farmLimit then update the dictionary
            // with the updated amount.
            else {
              // If the user ordered more than the guaranteedMaxQuantity and their an individual user
              // than we need to set the newLimit as they don't get all their order guarunteed.
              if (
                userInfo.userType === "individual" &&
                foodItem.quantity > 0 &&
                foodItem.guaranteedMaxQuantity !== "" &&
                foodItem.quantity > foodItem.guaranteedMaxQuantity
              ) {
                newLimit =
                  foodItemDoc.data().farmLimit - foodItem.guaranteedMaxQuantity;
              }
              updatedFoodItem.farmLimit = newLimit;
              // Update the amount that we deducted from this document.
              foodItemsUpdated[documentName] = deductedQuantity;
            }
            transaction.update(foodItemsDocRef, { ...updatedFoodItem });
            // return cancel in case it isn't an empty string.  If isn't empty the
            // the order will be cancelled and any deductions made to the database will
            // be restored.
            return cancel;
          });
        })
        .then((cancel) => {
          // Add that this item has completed it's update.
          completedUpdates += 1;
          // If the number of completed updates equals the number of farm limited
          // items then we know they have all been updated.
          if (completedUpdates === farmLimitedItems.length) {
            // IF cancel isn't empty then there was an item that went over and so the
            // order will be cancelled and we will have to restore any items that were deducted.
            if (cancel !== "") {
              OrderIncompleteRestoreItems(foodItemsUpdated);
              // stop placing the order so the user can fix their order.
              setPlacingOrder(false);
              // Send an alert to the user to tell them of the error.
              Swal.fire(cancel);
            }
            // If all the items were fine then we can continue placing the order.
            else {
              FarmLimitsCompletePlacingOrder(
                donationSubsidy,
                farmerInfo,
                userInfo,
                foodItems,
                batch,
                database,
                firebase,
                contactMethods,
                customerContribution,
                setFoodItems,
                costTotal,
                grandTotal,
                setPlacingOrder,
                setPlacingBatchesOrder,
                setSnackBarOpen,
                handleUserInfoChange,
                basketOrder,
                checkList,
                selectedDate,
                importedOrder,
                communityOrders,
                addToExistingOrder,
                modifiedUserPickups,
                farmTotals,
                setCommunityFoodItems,
                pantryFoodItems,
                setPantryFoodItems,
                setAddingToPantry,
                setImportedAddingToPantry,
                foodItemsUpdated,
                marketplaceMessage,
                marketplaceMessageEnabled,
                deliveryFee,
                cashPortion,
                creditPortion,
                extraQuantitiesDict,
                paymentMethod,
              );
            }
          }
        })
        .catch((err) => {
          throw err;
        });
    });
  }
}
