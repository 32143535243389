// This dialog is for a distribution location to record a payment from a user.
// It dipslays their orderHistory, deposits, orderNotes, and refunds.  When the
// distribution location updates the payment history it will be recorded in the database.
import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import PaymentEntry from "../Components/PaymentEntry.jsx";
import RefundEntry from "../Components/RefundEntry.jsx";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RecordPaymentHistory from "../Functions/RecordPaymentHistory.js";
import OrderSyncCheck from "../Functions/OrderSyncCheck.js";
import { ChangeOrderLogContext } from "../../MyAccount.js";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import SyncDisabledRoundedIcon from "@material-ui/icons/SyncDisabledRounded";
import EditCustomerPaymentDialog from "./EditCustomerPaymentDialog.jsx";
import WalletBalance from "../../../components/dialogs/Components/WalletBalance.jsx";
import F2PMCreditsAdjustment from "../Functions/F2PMCreditsAdjustment.js";

// The dialog to display payment history.
function EnterCustomerPaymentBox(props) {
  const {
    open,
    onClose,
    distributedDate,
    orderDate,
    orderPaymentHistory,
    imported,
    location,
    user,
  } = props;

  // This contains the orderLog and updateOrderLog so that when the user updates
  // the order it displays correctly on the myAccount Page
  // eslint-disable-next-line no-unused-vars
  const { orderLog, updateOrderLog, updatePaymentHistory } = useContext(
    ChangeOrderLogContext,
  );

  const handleClose = () => {
    onClose(false);
  };

  // When the user saves their changes.
  const handleSave = (e) => {
    e.preventDefault();
    // Checks the total amount that needs to be adjusted given all the
    // changes to refunds, and deposits.
    const totalBalanceChange = F2PMCreditsAdjustment(
      userPaymentHistory,
      deposits,
      refunds,
    );

    // Saves the changes to the database.
    RecordPaymentHistory(
      orderDate,
      deposits,
      refunds,
      orderNotes,
      imported,
      location,
      user,
      updatePaymentHistory,
      totalBalanceChange,
      communityWallet,
      setCommunityWallet,
      description,
    );
    onClose(false);
  };

  // When the user wants to add another deposit.
  const handleAddDeposit = () => {
    // Creates a temp array to add the new deposit.
    const depositsTemp = [...deposits];
    // Set some default values for the new deposit with today's date.
    depositsTemp.push({ method: "ETransfer", amount: 0, date: defaultDate });
    // Update the deposits hook.
    setDeposits([...depositsTemp]);
  };

  // When a user needs to remove a deposit.
  const handleRemoveDeposit = (index) => {
    // Creates a temp array to remove the deposit.
    const depositsTemp = [...deposits];
    // Remove the deposit from the array.
    depositsTemp.splice(index, 1);
    // Update the deposits hook.
    setDeposits([...depositsTemp]);
  };

  // When the user wants to add another refund.
  const handleAddRefund = () => {
    // Creates a temp array to add the new refund.
    const refundsTemp = [...refunds];
    // Set some default values for the new refund with today's date.
    refundsTemp.push({ amount: 0, date: defaultDate });
    // Update the refunds hook.
    setRefunds([...refundsTemp]);
  };

  // When a user needs to remove a refund.
  const handleRemoveRefund = (index) => {
    // Creates a temp array to remove the refund.
    const refundsTemp = [...refunds];
    // Remove the refund from the array.
    refundsTemp.splice(index, 1);
    // Update the refunds hook.
    setRefunds([...refundsTemp]);
  };

  // When the user makes modifications to the notes of the order history.
  const handleNotesChange = (e) => {
    // Sets the new value.
    const value = e.target.value;
    // Updaes the notes hook.
    setOrderNotes(value);
  };

  // When the user changes a deposit's fields.  Takes in the change, the index
  // of the effected deposit, if the method of payment was changes, if the date of deposit
  // was changed.
  const handleDepositChange = (e, index, methodChange, dateChange) => {
    // Sets the value to 0
    let value = 0;
    // Creates a temp array to update the deposit.
    const depositsTemp = [...deposits];
    // If the method of payment was changed.
    if (methodChange) {
      // read the value.
      value = e.target.value;
      // Update the deposit's method.
      depositsTemp[index].method = value;
    }
    // If the date of the deposit was changed.
    else if (dateChange) {
      // Update the value.
      value = e;
      // If there is no value return null.
      if (value === null) {
        return null;
      }
      // Format the date to be YYYY-MM-DD
      const formattedDate = new Date(value);

      const y = formattedDate.getFullYear();
      const m = formattedDate.getMonth() + 1;
      const d = formattedDate.getDate();

      value =
        "" + (m < 10 ? "0" : "") + m + "-" + (d < 10 ? "0" : "") + d + "-" + y;

      // Update the date of the deposit.
      depositsTemp[index].date = value;
    }
    // Otherwise it was updating the amount.
    else {
      // Read the amount and turn it into a float.
      // If there is not value then set it to empty.
      if (e.target.value === "") {
        value = "";
      } else {
        value = parseFloat(e.target.value);
      }

      // Set the amount for the deposit.
      depositsTemp[index].amount = value;
    }
    // Update the hook.
    setDeposits([...depositsTemp]);
  };

  // When the user changes a refund's fields.  Takes in the change, the index
  // of the effected deposit, and if the date of deposit was changed.
  const handleRefundChange = (e, index, dateChange) => {
    // Sets the value to 0
    let value = 0;
    // Creates a temp array to update the deposit.
    const refundsTemp = [...refunds];
    // If the date of the refund was changed.
    if (dateChange) {
      // Update the value.
      value = e;
      // If there is no value return null.
      if (value === null) {
        return null;
      }
      // Format the date to be YYYY-MM-DD
      const formattedDate = new Date(value);

      const y = formattedDate.getFullYear();
      const m = formattedDate.getMonth() + 1;
      const d = formattedDate.getDate();

      value =
        "" + (m < 10 ? "0" : "") + m + "-" + (d < 10 ? "0" : "") + d + "-" + y;

      // Update the date of the deposit.
      refundsTemp[index].date = value;
    }
    // Otherwise it was updating the amount.
    else {
      // Read the amount and turn it into a float.
      value = parseFloat(e.target.value);
      // If there is not value then set it to empty.
      if (!value) {
        value = "";
      }
      // Set the amount for the refund.
      refundsTemp[index].amount = value;
    }
    // Update the hook.
    setRefunds([...refundsTemp]);
  };

  // Stores the deposits that will be used by the user to update the deposit history.
  const [deposits, setDeposits] = useState([]);
  // Stores the refunds that will be used by the user to update the refund history.
  const [refunds, setRefunds] = useState([]);
  // Stores the order notes that will be used by the user to update the order notes.
  const [orderNotes, setOrderNotes] = useState("");
  // Stores the date that will be choosen for a deposit or refund by default which is
  // set to today's date.
  const [defaultDate, setDefaultDate] = useState("");
  // Stores the user's payment history.  Only used for the orderDate.
  const [orderHistory, setOrderHistory] = useState({});
  // Displays the each payment of this user.
  const [orderHistoryArrayDates, setOrderHistoryArrayDates] = useState([]);
  // The total amount that has been deposited by the user.
  const [totalDeposited, setTotalDeposited] = useState(0);
  // The total amount that has been refunded by the user.
  const [totalRefunded, setTotalRefunded] = useState(0);
  const [communityWallet, setCommunityWallet] = useState({});
  // The desciption of the transaction that we will use in the user's wallet
  // if they are choosing to pay that way.
  const description = `Recorded payment for ${distributedDate} at ${location}`;
  // A boolean that says whether the orderNotes changes have been synced.
  let syncOrderNotes = true;
  // A boolean that says whether the deposits changes have been synced.
  let syncDeposits = [];
  // A boolean that says whether a removed deposit has been synced.
  let syncDepositsLength = true;
  // A boolean that says whether the refunds changes have been synced.
  let syncRefunds = [];
  // A boolean that says whether a removed refund has been synced.
  let syncRefundsLength = true;
  // Set this to 0 in case there is no totalOwed due to a rendering delay
  let totalOwed = 0;

  // Create a dictionary for the specific user's payment history. deposits, orderNotes,
  // orderRefunds, orderHistory, totalOwed,and distributionLocation.
  let userPaymentHistory = {};

  useEffect(() => {
    // Using the order's orderDate we can find the payment history for this specific
    // order.  JSON parsing creates a deep copy.
    const currentPaymentHistory = JSON.parse(
      JSON.stringify(orderPaymentHistory[orderDate]),
    );

    // If the user is not imported.
    if (!imported) {
      // Set their payment history.
      userPaymentHistory = currentPaymentHistory.communityOrders[user];
    }
    // If the user is imported.
    else {
      userPaymentHistory =
        currentPaymentHistory.importedOrder[location].communityOrders[user];
    }

    // In case the orderPaymentHistory hasn't been updated yet and is still needing
    // to be updated we can't move forward with this function.
    if (!userPaymentHistory) {
      return;
    }

    // Create a default date for in the format YYYY-MM-DD to be used to set default
    // dates for deposits and refunds that do no have dates.
    let defaultDateTemp = new Date();

    const y = defaultDateTemp.getFullYear();
    const m = defaultDateTemp.getMonth() + 1;
    const d = defaultDateTemp.getDate();

    defaultDateTemp =
      "" + (m < 10 ? "0" : "") + m + "-" + (d < 10 ? "0" : "") + d + "-" + y;
    setDefaultDate(defaultDateTemp);

    // Set a variable for the order history that will be displayed to the user.
    const orderHistoryTemp = userPaymentHistory.orderHistory;
    setOrderHistory(userPaymentHistory.orderHistory);

    // Sort the orderHistory to show oldest to newest order.
    setOrderHistoryArrayDates(Object.keys(orderHistoryTemp).sort());

    // Since the deposits were originally being saved as dictionaries they need to
    // be converted to arrays.  They were only empty dictionaries so just set them as
    // array and empty array.  If they are already an array we know that the deposit has been updated and
    // so just read the database value.
    let userPaymentHistoryDepositsTemp = Array.isArray(
      userPaymentHistory.deposits,
    )
      ? [...userPaymentHistory.deposits]
      : [];

    // If deposits are empty, populate them based on paymentMethod
    if (userPaymentHistoryDepositsTemp.length === 0) {
      if (
        userPaymentHistory.paymentMethod &&
        userPaymentHistory.paymentMethod.length > 0
      ) {
        userPaymentHistoryDepositsTemp = userPaymentHistory.paymentMethod.map(
          (userChosenMethod) => ({
            method: Object.keys(userChosenMethod)[0],
            // We put it in toFixed first and then back to parseFloat to round up the number
            // but keep it as a number not a string.
            amount: parseFloat(
              parseFloat(Object.values(userChosenMethod)[0].toFixed(2)),
            ),
            date: defaultDateTemp,
          }),
        );
      } else {
        userPaymentHistoryDepositsTemp = [
          {
            method: "ETransfer",
            // We put it in toFixed first and then back to parseFloat to round up the number
            // but keep it as a number not a string.
            amount: parseFloat(
              parseFloat(userPaymentHistory.totalOwed).toFixed(2),
            ),
            date: defaultDateTemp,
          },
        ];
      }
    }

    // Since the refunds were originally being saved as dictionaries they need to
    // be converted to arrays.  They were only empty dictionaries so just set them as
    // array and empty array.
    let userPaymentHistoryRefundsTemp = [];

    // If they are already an array we know that the refund has been updated and
    // so just read the database value.
    if (Array.isArray(userPaymentHistory.orderRefunds)) {
      // Turn the Refunds into an array that will be placed into a hook to be used
      // to update the user's refunds.
      userPaymentHistoryRefundsTemp = [...userPaymentHistory.orderRefunds];
      // If there are no refunds yet then set a default first value.
      if (userPaymentHistoryRefundsTemp.length === 0) {
        userPaymentHistoryRefundsTemp = [{ amount: 0, date: defaultDateTemp }];
      }
    }
    // If userPaymentHistory isn't an array then we know it's never been updated.
    else {
      userPaymentHistoryRefundsTemp = [{ amount: 0, date: defaultDateTemp }];
    }

    // set the hooks.
    setDeposits([...userPaymentHistoryDepositsTemp]);
    setRefunds([...userPaymentHistoryRefundsTemp]);
    setOrderNotes(userPaymentHistory.orderNotes);
  }, [orderPaymentHistory]);

  // Counts the total amount of deposits and refunds for the user.
  useEffect(() => {
    let totalDepositedTemp = 0;
    let totalRefundedTemp = 0;

    deposits.forEach((deposit) => {
      totalDepositedTemp += parseFloat(deposit.amount);
    });

    refunds.forEach((refund) => {
      totalRefundedTemp += parseFloat(refund.amount);
    });

    setTotalDeposited(totalDepositedTemp);
    setTotalRefunded(totalRefundedTemp);
  }, [deposits, refunds]);

  // If the user is not imported.
  if (!imported) {
    // Set their payment history.
    userPaymentHistory = orderPaymentHistory[orderDate].communityOrders[user];
    if (userPaymentHistory) {
      // In case the deposits were saved as an array we need to put them as an array.
      if (!Array.isArray(userPaymentHistory.deposits)) {
        userPaymentHistory.deposits = [];
      }
      // In case the orderRefunds were saved as an array we need to put them as an array.
      if (!Array.isArray(userPaymentHistory.orderRefunds)) {
        userPaymentHistory.orderRefunds = [];
      }
    }
  }
  // If the user is imported.
  else {
    userPaymentHistory =
      orderPaymentHistory[orderDate].importedOrder[location].communityOrders[
        user
      ];
    if (userPaymentHistory) {
      // In case the deposits were not saved as an array we need to put them as an array.
      if (!Array.isArray(userPaymentHistory.deposits)) {
        userPaymentHistory.deposits = [];
      }
      // In case the orderRefunds were not saved as an array we need to put them as an array.
      if (!Array.isArray(userPaymentHistory.orderRefunds)) {
        userPaymentHistory.orderRefunds = [];
      }
    }
  }

  // Make sure that userPaymentHistory exists and has been updated.
  if (userPaymentHistory) {
    [
      syncOrderNotes,
      syncDeposits,
      syncDepositsLength,
      syncRefunds,
      syncRefundsLength,
    ] = OrderSyncCheck(
      orderNotes,
      userPaymentHistory,
      syncOrderNotes,
      deposits,
      syncDeposits,
      syncDepositsLength,
      refunds,
      syncRefunds,
      syncRefundsLength,
    );
    // If userPaymentHistory exists then update the totalOwed to the correct amount.
    totalOwed = userPaymentHistory.totalOwed;
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Customer Payment for {distributedDate} at {location}
      </DialogTitle>
      <DialogContent>
        <span style={{ display: "flex" }}>
          <h5>
            <u>Total Owed</u>
          </h5>
          <EditCustomerPaymentDialog
            orderDate={orderDate}
            imported={imported}
            distributionLocation={location}
            user={user}
            updatePaymentHistory={updatePaymentHistory}
            totalOwed={totalOwed}
          />
        </span>
        {orderHistoryArrayDates.map((date) => (
          <span key={date}>
            {date} : ${orderHistory[date].toFixed(2)}
            <br />
          </span>
        ))}

        <table border="1">
          <thead>
            <tr>
              <th>Payments</th>
              <th>Refunds</th>
              <th>Order Cost</th>
              <th>User Owes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{totalDeposited.toFixed(2)}</td>
              <td>{totalRefunded.toFixed(2)}</td>
              <td>{totalOwed.toFixed(2)}</td>
              <td>{(totalOwed - totalDeposited + totalRefunded).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>

        <WalletBalance
          userId={user.split("-")[2]}
          communityWallet={communityWallet}
          setCommunityWallet={setCommunityWallet}
        />

        <form onSubmit={handleSave}>
          {!syncDepositsLength && (
            <h5 style={{ color: "red" }}>
              Deposit Removal Not Synced
              <SyncDisabledRoundedIcon style={{ fill: "red" }} />
            </h5>
          )}
          {deposits.map((deposit, index) => (
            <PaymentEntry
              deposit={deposit}
              key={index}
              index={index}
              handleDepositChange={handleDepositChange}
              handleRemoveDeposit={handleRemoveDeposit}
              syncDeposits={syncDeposits}
            />
          ))}

          <IconButton onClick={() => handleAddDeposit()}>
            <AddCircleOutlineOutlinedIcon
              fontSize="large"
              style={{ color: "cyan" }}
            />
          </IconButton>

          {!syncRefundsLength && (
            <h5 style={{ color: "red" }}>
              Refund Removal Not Synced
              <SyncDisabledRoundedIcon style={{ fill: "red" }} />
            </h5>
          )}
          {refunds.map((refund, index) => (
            <RefundEntry
              refund={refund}
              key={index}
              index={index}
              handleRefundChange={handleRefundChange}
              handleRemoveRefund={handleRemoveRefund}
              syncRefunds={syncRefunds}
            />
          ))}

          <IconButton onClick={() => handleAddRefund()}>
            <AddCircleOutlineOutlinedIcon
              fontSize="large"
              style={{ color: "cyan" }}
            />
          </IconButton>

          <span>
            <h5>
              Order Notes
              {syncOrderNotes ? (
                <SyncRoundedIcon style={{ fill: "green" }} />
              ) : (
                <SyncDisabledRoundedIcon style={{ fill: "red" }} />
              )}
            </h5>
            <TextField
              label="Notes"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={orderNotes}
              onChange={handleNotesChange}
            />
          </span>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

EnterCustomerPaymentBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function EnterCustomerPaymentDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        PAYMENT
      </Button>

      <EnterCustomerPaymentBox
        open={open}
        onClose={handleClose}
        distributedDate={props.distributedDate}
        orderDate={props.orderDate}
        orderPaymentHistory={props.orderPaymentHistory}
        imported={props.imported}
        location={props.location}
        user={props.user}
      />
    </div>
  );
}
