// This Dialog appears when the user clicks on "CHECK FOR UPDATES" button the
// MyAccount page when opening the community orders.  It will check for the differences
// between the distribution location's orders and the database's orders.
import React, { useState, useContext } from "react";
import firebase from "./../Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../styles/MyAccount.css";
import PriceTotals from "../../functions/PriceTotals.js";
import PackageTotals from "../../functions/PackageTotals.js";
import CheckCustomerUpdate from "../../functions/CheckCustomerUpdate.js";
import { AuthContext } from "../../components/authentication/Auth.js";
import {
  ChangeOrderLogContext,
  CommunityOrderChunksContext,
} from "../../pages/MyAccount.js";
import CreateVisibleCommunityOrders from "./functions/CreateVisibleCommunityOrders.js";
import UpdatePaymentHistory from "../../pages/MyAccountPage/Functions/UpdatePaymentHistory.js";
import UpdateCommunityOrdersDatabase from "../../pages/BasketPage/Functions/OrderUpdates/UpdateCommunityOrdersDatabase.js";

// This is used to print on the webpage the user receipts.
function UserReceipt({ idx, userOrder, user, handleOrderRemoved }) {
  // Spilts the name up so it can use firstName, lastName and userId
  const name = user.split("-");

  // A dictionary of the the farms(keys) and the total owed to them (values)
  const farmTotals = PriceTotals(userOrder.foodList, true);
  // A dictionary of the the farms(keys) and the total packaging fees to them (values)
  const packageTotals = PackageTotals(userOrder.foodList);
  // The total including the donation or subsidy
  let grandTotal = 0;

  // The total packaging fee.
  let packageFeeTotal = 0;

  // The total volunteer Fee Cash
  const volunteerFeeCash = userOrder.cashPortion;
  // The total volunteer credits.
  const volunteerFeeCredits = userOrder.creditPortion;

  // Sum up the grandTotal of all the farms together.
  Object.keys(farmTotals).forEach((farmTotal) => {
    grandTotal += farmTotals[farmTotal];
  });

  // Cycle through the package Totals and add them all up.
  Object.keys(packageTotals).forEach((packageTotal) => {
    packageFeeTotal += packageTotals[packageTotal];
  });
  // Add the package fees to the grandTotal.
  grandTotal += packageFeeTotal;

  // Add in the subdidy and donation amounts
  if (userOrder.customerContribution === "donation") {
    grandTotal += userOrder.donationSubsidy;
  } else {
    grandTotal -= userOrder.donationSubsidy;
  }

  // Add the volunteer cash portion to the grandTotal.
  if (parseFloat(volunteerFeeCash) > 0) {
    grandTotal += parseFloat(volunteerFeeCash);
  }

  // Make sure that the grandTotal isn't ever negative when it rounds to 0.
  if (grandTotal < 0 && grandTotal > -0.01) {
    grandTotal = 0;
  }

  return (
    <div key={idx}>
      <h5>
        {name[0]} {name[1]}:{" "}
        <Button
          color="secondary"
          onClick={() => handleOrderRemoved(name, userOrder.orderDate)}
        >
          Remove
        </Button>
      </h5>
      {userOrder.foodList.map((food, itemNumber) => {
        // The price of the item for the individual.
        let individualPrice = parseFloat(
          food.price / (food.distributionQuantity / food.individualQuantity),
        );
        if (food.packageFee > 0) {
          individualPrice += parseFloat(food.packageFee);
        }
        const individualPriceTotal = parseFloat(
          individualPrice * food.quantity,
        ).toFixed(2);
        individualPrice = individualPrice.toFixed(2);

        return (
          <p key={itemNumber} style={{ margin: "0px 0px 0px 20px" }}>
            {itemNumber + 1}. {food.farmName} <strong>{food.item}</strong>{" "}
            {food.individualDescription} x{food.quantity} -- ${individualPrice}{" "}
            (${individualPriceTotal}){food.packageFee > 0 && <>*</>}
            {food.packageFee > 0 && (
              <p className="Item-Package-Fee">
                *includes package fee ${parseFloat(food.packageFee).toFixed(2)}
                /per item
              </p>
            )}
          </p>
        );
      })}
      <div className="Price-Totals">
        <h5 className="Contact-Method-Title Food-Item-Header">
          {" "}
          Farm Price Totals{" "}
        </h5>
        {Object.keys(farmTotals).map((farmTotal, index) => (
          <p key={farmTotal + index} className="Prices-Per-Farm">
            {farmTotal} : ${farmTotals[farmTotal].toFixed(2)}
            {packageTotals[farmTotal] > 0 && (
              <span
                style={{ verticalAlign: "super" }}
                className="Item-Package-Fee"
              >
                + ${packageTotals[farmTotal].toFixed(2)} pkg fee
              </span>
            )}
          </p>
        ))}
        <span style={{ fontSize: "12px", textAlign: "end" }}>
          {packageFeeTotal > 0 && (
            <p className="Total-Packaging-Fees">
              Total Package Fees : ${packageFeeTotal.toFixed(2)}
            </p>
          )}
          {parseFloat(volunteerFeeCash) > 0 && (
            <p className="Total-Packaging-Fees">
              Participation Fee (Cash) : ${volunteerFeeCash}
            </p>
          )}
          {parseFloat(volunteerFeeCredits) > 0 && (
            <p className="Total-Packaging-Fees">
              Participation Fee (Credits) : {volunteerFeeCredits}
            </p>
          )}
        </span>
        <span className="Customer-Payment-Edit-Row">
          {userOrder.customerContribution === "donation" ? (
            <p className="Subsidy-Donation-Totals" style={{ color: "green" }}>
              Donation : ${userOrder.donationSubsidy.toFixed(2)}
            </p>
          ) : (
            <p className="Subsidy-Donation-Totals" style={{ color: "red" }}>
              Subsidy : ${userOrder.donationSubsidy.toFixed(2)}
            </p>
          )}
        </span>
        <p>
          <b>Total </b>: ${grandTotal.toFixed(2)}
        </p>
      </div>
    </div>
  );
}

// This is the dialog box that opens when a user clicks on CHECK FOR UPDATES
function CustomerOrdersUpdateBox(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  let ordersExistToUpdate = false;

  // When the user removes a user from being added to the order update.
  const handleOrderRemoved = (name, orderDate) => {
    // Creates the firstName-lastName-userID-orderDate format used in the database
    // for the user's orders
    let user = name.join("-");
    user = user.concat("-");
    user = user.concat(orderDate);
    // Boolean to say if there are any orders to update
    // uses a temporary community orders dictionary
    const communityOrdersTemp = props.communityOrders;
    // Remove the community order at hand
    delete communityOrdersTemp[user];
    // Removes the dictionaries of the farms and foods before being saved to the database
    Object.keys(communityOrdersTemp).forEach((order) => {
      delete communityOrdersTemp[order].foodDict;
      delete communityOrdersTemp[order].farmDict;
    });

    // Updates the hooks holding the new orders to show the list with the order
    // removed
    props.setNewOrders({ ...communityOrdersTemp });
  };

  // Calls CreateVisibleCommunityOrders which takes the community orders placed for
  // this distribution location and combines orders that the same user might have
  // placed as well as creates a dictionary for both the foodlist and the farmerList
  // so that they're easier to work with to create the downloadable file and popup.
  const communityOrders = CreateVisibleCommunityOrders(
    { ...props.communityOrders },
    null,
    null,
    false,
    [],
  );

  // Use `some()` to set `ordersExistToUpdate` to true if any location has orders
  ordersExistToUpdate = Object.keys(communityOrders).some(
    (location) => Object.keys(communityOrders[location]).length > 0,
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Additional orders for {props.selectedDate}
      </DialogTitle>
      <DialogContent>
        {props.checkingUpdate ? (
          <>
            {Object.keys(communityOrders).map((location, i) => (
              <>
                <h4>
                  <u>{location}</u>
                </h4>
                {Object.keys(communityOrders[location]).length !== 0 ? (
                  <span>
                    {Object.keys(communityOrders[location]).map((user, idx) => (
                      <UserReceipt
                        idx={idx}
                        key={idx}
                        userOrder={communityOrders[location][user]}
                        user={user}
                        handleOrderRemoved={handleOrderRemoved}
                      />
                    ))}
                  </span>
                ) : (
                  <p>No Community Orders with this Order.</p>
                )}
              </>
            ))}
          </>
        ) : (
          <span style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </span>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
        {ordersExistToUpdate && (
          <Button color="primary" onClick={props.handleUpdate}>
            UPDATE
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

CustomerOrdersUpdateBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function CustomerOrdersUpdateDialog(props) {
  const [open, setOpen] = useState(false);

  // Determines if the items have been loaded yet.  Once the database has been
  // checked this will be set to true.
  const [checkingUpdate, setCheckingUpdate] = useState(false);

  const { userInfo } = useContext(AuthContext);

  // This contains the orderLog and updateOrderLog so that when the user updates
  // the order it displays correctly on the myAccount Page
  const { orderLog, updateOrderLog, updatePaymentHistory } = useContext(
    ChangeOrderLogContext,
  );

  // communityOrderChunks are in {orderdate: [communityOrders, overFlowCommunityOrders1, etc.]}
  const { communityOrderChunks = [], setCommunityOrderChunks = () => {} } =
    useContext(CommunityOrderChunksContext) || {};

  // This a dictionary with the newOrders saved to it.
  const [newOrders, setNewOrders] = useState({});

  // Calls this function when checkingUpdate hasn't been set to true yet to see if
  // there are any new orders that have come in.
  if (open && !checkingUpdate) {
    CheckCustomerUpdate(
      props.communityOrders,
      props.selectedDate,
      userInfo,
      setCheckingUpdate,
      setNewOrders,
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCheckingUpdate(false);
  };

  // When the user wants to pull the new orders in and update the order they do so
  // by calling this function
  const handleUpdate = () => {
    const database = firebase.firestore();
    const docRef = database
      .collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .collection("Orders")
      .doc(props.order.orderDate);
    const batch = database.batch();

    // A temporary orderLog to update the myAccount page
    const orderLogTemp = [...orderLog];

    // Checks to make sure that pantryFoodItems exists otherwise it sets it to an
    // empty array.
    if (!orderLogTemp[props.orderIndex].pantryFoodItems) {
      orderLogTemp[props.orderIndex].pantryFoodItems = [];
    }

    // Holds the order in a temporary variable.
    const orderTemp = { ...orderLogTemp[props.orderIndex] };

    // Holds a dictionary of all the newOrders that are being added to the order.
    const newOrdersTemp = { ...newOrders };

    // Cycle throught the newOrders
    Object.keys(newOrders).forEach((userOrder) => {
      // Cycle through each order's foodList
      newOrders[userOrder].foodList.forEach((userFoodItem, foodIndex) => {
        // If a limit exists then we will check if the order is already in
        // the foodList.  If it is then the user isn't actually ordering from the
        // pantry but taking from an item that was ordered this week.
        if (userFoodItem.limit) {
          // Check that the order is in the foodList already.
          const existsInFL = orderTemp.foodList.findIndex((dLFoodItem) => {
            return (
              userFoodItem.item === dLFoodItem.item &&
              userFoodItem.individualDescription ===
                dLFoodItem.individualDescription &&
              userFoodItem.individualQuantity === dLFoodItem.individualQuantity
            );
          });
          // If the foodItem does exist in the foodList.
          if (existsInFL !== -1) {
            // Delete the limit field since they aren't actually taking it from
            // the pantry but it's just what was leftover from the order this week.
            delete newOrdersTemp[userOrder].foodList[foodIndex].limit;
          }
          // Otherwise it wasn't in the foodList so check if the item was in the
          // pantryList
          else {
            // Find the index of the pantryItem that matches the foodItem.
            const existsInPL = orderTemp.pantryFoodItems.findIndex(
              (pLFoodItem) => {
                return (
                  userFoodItem.item === pLFoodItem.item &&
                  userFoodItem.individualDescription ===
                    pLFoodItem.individualDescription &&
                  userFoodItem.individualQuantity ===
                    pLFoodItem.individualQuantity
                );
              },
            );
            // If it was in the pantry List then update the quantity of the pantry
            // item to include this person's order.  We don't need to check that
            // this item was available since the user couldn't have ordered it if it wasn't.
            if (existsInPL !== -1) {
              orderLogTemp[props.orderIndex].pantryFoodItems[
                existsInPL
              ].quantity =
                parseInt(orderTemp.pantryFoodItems[existsInPL].quantity) +
                parseInt(userFoodItem.quantity);
            }
            // Push the foodItem into the list.
            else {
              orderLogTemp[props.orderIndex].pantryFoodItems.push(userFoodItem);
            }
          }
        }
      });
    });
    // Combines the old and new lists together
    const updatedOrder = Object.assign({}, props.communityOrders, newOrders);

    // If the order being updated is imported than it will update the importedOrder
    // key in the database and update the orderLog correctly
    if (props.imported) {
      if (props.order.orderDate > "202106150000000000000") {
        // orders = ["importedOrder", props.location, "communityOrders"].join(".");
        orderLogTemp[props.orderIndex].importedOrder[
          props.location
        ].communityOrders = { ...updatedOrder };
      } else {
        // orders = "importedOrder";
        orderLogTemp[props.orderIndex].importedOrder = { ...updatedOrder };
      }
    } else {
      orderLogTemp[props.orderIndex].communityOrders = { ...updatedOrder };
    }
    // Users -> userId -> Orders -> date -> communityOrders/importedOrders ->
    // FirstName-LastName-userId-date.  Updates either the communityOrders or the
    // importedOrder and then updates the pantryFoodItems.
    batch.update(docRef, {
      pantryFoodItems: orderLogTemp[props.orderIndex].pantryFoodItems,
    });

    // Update the order document of this distribution location.
    UpdateCommunityOrdersDatabase(
      "CompleteOverwrite",
      batch,
      communityOrderChunks,
      setCommunityOrderChunks,
      updatedOrder,
      props.order.orderDate,
    );

    // When the customers orders are updated there may be new orders and so the users
    // total owed will changed and so this needs to be updated.
    UpdatePaymentHistory(
      props.imported,
      props.location,
      newOrders,
      props.order.orderDate,
      userInfo,
      updatePaymentHistory,
    );

    batch.commit();
    updateOrderLog([...orderLogTemp]);
    handleClose();
  };

  const buttonTitle = "CHECK FOR UPDATES";

  return (
    <div>
      <div className="Order-Again-Button">
        <div className="Order-Again-Button">
          <Button color="primary" onClick={handleClickOpen}>
            {buttonTitle}
          </Button>
        </div>
      </div>

      <CustomerOrdersUpdateBox
        open={open}
        checkingUpdate={checkingUpdate}
        onClose={handleClose}
        communityOrders={newOrders}
        selectedDate={props.selectedDate}
        imported={props.imported}
        order={props.order}
        orderIndex={props.orderIndex}
        setNewOrders={setNewOrders}
        handleUpdate={handleUpdate}
      />
    </div>
  );
}
