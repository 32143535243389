import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import "../../styles/MyAccount.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ConfirmOrderMessages from "../../pages/BasketPage/ConfirmOrderMessages";
import FarmOrdersNotMetDialog from "../dialogs/FarmOrdersNotMetDialog.jsx";
import BasketMarketplaceMessageDialog from "../../pages/BasketPage/BasketMarketplaceMessageDialog.jsx";
import VolunteerFee from "../../pages/BasketPage/Components/VolunteerFee.jsx";
import DonationSubsidyRadioGroup from "../../pages/BasketPage/Components/DonationSubsidyRadioGroup.jsx";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import WalletBalance from "./Components/WalletBalance.jsx";

function ConfirmOrderBox(props) {
  const { onClose, open } = props;

  const [communityWallet, setCommunityWallet] = useState({});

  const handleClose = () => {
    onClose(false);
  };

  const handlePlaceOrder = () => {
    onClose(true);
  };

  // When the user checks a farm to add their items to the pantry this is called
  // which adds or removes it from the checklist.
  const handleToggle = (value) => () => {
    // Checks the value (the farm's name) that was sent in to see if it's already
    // in the checkList.  set the value to -1 if not.
    const currentIndex = props.checkList.indexOf(value);
    // Creates a temporary checklist to modify
    const checkListTemp = [...props.checkList];

    // Checks the result of the indexOf.  If it is -1 then the farm isn't in the
    // checkList and so it has to be added otherwise it has to be removed.
    if (currentIndex === -1) {
      checkListTemp.push(value);
    } else {
      checkListTemp.splice(currentIndex, 1);
    }
    // Updates the checkList
    props.setCheckList([...checkListTemp]);
  };

  // A Switch that determines if the user is placing a new order or adding to
  // an existing order
  const handleSwitchChange = () => {
    props.setAddToExistingOrder(!props.addToExistingOrder);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Please Select Your Payment Method
      </DialogTitle>
      <DialogContent>
        {/* If the user is and individual then we have to decide which message to display */}
        {props.userType === "individual" && (
          <>
            <div
              style={{
                position: "sticky",
                top: 0,
                background: "white",
                zIndex: 1000,
                padding: "8px 0",
                borderBottom: "1px solid #ccc",
              }}
            >
              <h3 style={{ margin: 0 }}>
                Total: ${props.grandTotal.toFixed(2)}
              </h3>
            </div>
            <div
              style={{
                border: "2px solid #000",
                borderRadius: "12px",
                padding: "16px",
                boxSizing: "border-box",
              }}
            >
              {props?.pickupLocation?.volunteerPercentage > 0 && (
                <VolunteerFee
                  costTotal={props.costTotal}
                  pickupLocation={props.pickupLocation}
                  userInfo={props.userInfo}
                  setDonationSubsidy={props.setDonationSubsidy}
                  customerContribution={props.customerContribution}
                  suggestedFees={props.suggestedFees}
                />
              )}
              <hr />
              <DonationSubsidyRadioGroup
                subsidyAmount={props.subsidyAmount}
                customerContribution={props.customerContribution}
                setCustomerContribution={props.setCustomerContribution}
                donationSubsidy={props.donationSubsidy}
                setDonationSubsidy={props.setDonationSubsidy}
                costTotal={props.costTotal}
                packageFeeTotal={props.packageFeeTotal}
                deliveryFee={props.deliveryFee}
                cashPortion={props.cashPortion}
                subsidyExplanation={props.subsidyExplanation}
                dollarColour={props.dollarColour}
                textFieldColour={props.textFieldColour}
              />
              <hr />
              <FormControl style={{ marginTop: "10px" }}>
                {/* Reset the value of the donationSubsidy to 0 when the user
                changes to the subsidy radioButton.  This is to prevent mistakenly
                going into the negative if the value is bigger than what is owed */}
                <FormLabel>How will you pay for your order?</FormLabel>
                <span className="Radio-TextField">
                  <RadioGroup
                    aria-label="paymentMethod"
                    name="paymentMethod"
                    value={props.paymentMethod}
                    onChange={(e) => {
                      props.setPaymentMethod(e.target.value);
                    }}
                  >
                    <span className="Info-And-Radio-Button">
                      <FormControlLabel
                        value="ETransfer"
                        control={<Radio />}
                        label="Etransfer"
                      />
                    </span>
                    <span className="Info-And-Radio-Button">
                      <FormControlLabel
                        value="cash"
                        control={<Radio />}
                        label="Cash"
                      />
                    </span>
                    <span className="Info-And-Radio-Button">
                      <FormControlLabel
                        value="F2PMCredits"
                        control={<Radio />}
                        label="F2PM Credits"
                      />
                    </span>
                  </RadioGroup>
                </span>
              </FormControl>

              <hr />
              {props.paymentMethod === "F2PMCredits" && (
                <WalletBalance
                  userId={props.userInfo.userId}
                  communityWallet={communityWallet}
                  setCommunityWallet={setCommunityWallet}
                />
              )}
              {(props.paymentMethod === "ETransfer" ||
                props.paymentMethod === "cash") && (
                <div style={{ width: "400px" }}>
                  {/* If the user is donating regardless of amount then just let them confirm their order */}
                  {props.customerContribution === "donation" ? (
                    ConfirmOrderMessages(
                      "normalMessage",
                      props.userInfo,
                      props.grandTotal,
                      props.paymentMethod,
                    )
                  ) : (
                    <>
                      {/* If the user selected subsidy but didn't give an amount make them change to donation */}
                      {props.donationSubsidy === 0 ? (
                        ConfirmOrderMessages(
                          "subsidyZeroMessage",
                          props.userInfo,
                          props.grandTotal,
                          props.paymentMethod,
                        )
                      ) : (
                        <>
                          {/* If the user used a subsidy but still owes some money remind them they owe that amount */}
                          {props.grandTotal > "0" &&
                            ConfirmOrderMessages(
                              "subsidyOwingMessge",
                              props.userInfo,
                              props.grandTotal,
                              props.paymentMethod,
                            )}
                          {/* If the user used a subsidy and it covers the full amount then simple message */}
                          {props.grandTotal === 0 &&
                            ConfirmOrderMessages(
                              "subsidyCoveredMessage",
                              props.userInfo,
                              props.grandTotal,
                              props.paymentMethod,
                            )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}

        {props.userType === "distributionLocation" && (
          <List>
            <ListItem>
              <FormControlLabel
                label="Add to Existing Order"
                control={
                  <Switch
                    checked={props.addToExistingOrder}
                    onChange={() => handleSwitchChange()}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
            </ListItem>
            <ListItem>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  label="Display Marketplace Message: "
                  control={
                    <Switch
                      checked={props.marketplaceMessageEnabled}
                      onChange={(e) =>
                        props.setMarketplaceMessageEnabled(
                          !props.marketplaceMessageEnabled,
                        )
                      }
                      name="marketplaceMessageEnabled"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />

                <p>{props.marketplaceMessage}</p>
                <BasketMarketplaceMessageDialog
                  marketplaceMessage={props.marketplaceMessage}
                  marketplaceMessageEnabled={props.marketplaceMessageEnabled}
                  setMarketplaceMessage={props.setMarketplaceMessage}
                />
              </span>
            </ListItem>
            <h5>
              Check farms you wish to only show leftovers on Marketplace. This
              is for when you've placed your final order for the week with a
              farm.
            </h5>
            {props.farmerInfo.map((farm) => {
              const labelId = `checkbox-list-label-${farm.farmName}`;
              return (
                <ListItem
                  key={farm.farmName}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(farm.farmName)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={props.checkList.indexOf(farm.farmName) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${farm.farmName}`} />
                </ListItem>
              );
            })}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        {!(
          props.userType === "individual" &&
          props.customerContribution === "subsidy" &&
          props.donationSubsidy === 0
        ) && (
          <Button color="primary" onClick={handlePlaceOrder}>
            PLACE ORDER
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmOrderBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ConfirmOrderDialog(props) {
  const [open, setOpen] = useState(false);
  // Holds the farms' names that will have their items added to the pantry.
  const [checkList, setCheckList] = useState([]);

  // A flag hook that determines whether the user is adding to an existing order
  // or placing a new order
  const [addToExistingOrder, setAddToExistingOrder] = useState(false);

  // A flag that says whether the marketplace message is enabled or not.
  const [marketplaceMessageEnabled, setMarketplaceMessageEnabled] = useState(
    props.userInfo.marketplaceMessageEnabled,
  );

  // A hook to hold the marketplace message that will used to update. Init the message
  // that was originally used.
  const [marketplaceMessage, setMarketplaceMessage] = useState(
    props.userInfo.marketplaceMessage,
  );

  const [paymentMethod, setPaymentMethod] = useState("ETransfer");

  // Variable that holds whether or not the minimumOrder has been met
  // var minimumOrderMet = false;
  const [minimumOrderMet, setMinimumOrderMet] = useState(false);
  // Variable that holds whether or not the pickupLocation has been chosen
  const [pickupLocationSelected, setPickupLocationSelected] = useState(true);

  const farmerInfo = props.farmerInfo;
  const farmTotals = props.farmTotals;
  const userInfo = props.userInfo;
  const userType = userInfo.userType;
  // The grandTotal owed used to decide which message to display
  const grandTotal = props.grandTotal;
  const customerContribution = props.customerContribution;
  const donationSubsidy = props.donationSubsidy;
  const pickupLocation = props.pickupLocation;
  const costTotal = props.costTotal;
  const setDonationSubsidy = props.setDonationSubsidy;
  const suggestedFees = props.suggestedFees;
  const subsidyAmount = props.subsidyAmount;
  const setCustomerContribution = props.setCustomerContribution;
  const packageFeeTotal = props.packageFeeTotal;
  const deliveryFee = props.deliveryFee;
  const cashPortion = props.cashPortion;
  const subsidyExplanation = props.subsidyExplanation;
  const dollarColour = props.dollarColour;
  const textFieldColour = props.textFieldColour;

  useEffect(() => {
    // Checks that the minimumOrder is of the farm and compares it to the farmTotals
    // and returns if this is false or true.  It's used in the .some below
    const checkminimumOrder = (farm) =>
      farm.minimumOrder > farmTotals[farm.farmName];

    // If user is not a restaurant or an individual check to see if there exists
    // a farm where the totals are not met which would have checkminimumOrder
    // return true.  If it does then minimumOrderMet will be set to true which
    // means that there is a farm that hasn't met the minimumOrder and it the user
    // can't place the order.
    if (
      farmerInfo !== undefined &&
      userType !== "restaurant" &&
      userType !== "individual"
    ) {
      setMinimumOrderMet(farmerInfo.some(checkminimumOrder));
    }

    if (userInfo.pickupLocation === undefined && userType === "individual") {
      setPickupLocationSelected(false);
    }
  }, [props, userInfo]);

  const handleClickOpen = () => {
    setOpen(true);
    // Opens the announcement dialog if there is one for "individual" userTypes
    if (userType === "individual") {
      props.setOpenNotice(true);
    }
  };

  const handleClose = (placed) => {
    setOpen(false);
    if (placed) {
      // Includes the checklist of farms to put into pantry mode
      // Add to the an existing order flag
      // The updated marketplace message.
      // The marketplace message enabled flag.
      props.placeOrder(
        [...checkList],
        addToExistingOrder,
        marketplaceMessage,
        marketplaceMessageEnabled,
        paymentMethod,
      );
    }
  };

  return (
    <div>
      {minimumOrderMet ? (
        <FarmOrdersNotMetDialog handleClickOpen={handleClickOpen} />
      ) : (
        <div>
          {pickupLocationSelected ? (
            <Button
              onClick={handleClickOpen}
              style={{ width: "100%", background: "#9FBF59", color: "white" }}
            >
              {props.message}
            </Button>
          ) : (
            <Link
              to={{
                pathname: "/DistributionLocationSelection",
                query: "/Basket",
              }}
            >
              <Button color="primary">SELECT A PICKUP LOCATION</Button>
            </Link>
          )}
        </div>
      )}

      <ConfirmOrderBox
        open={open}
        onClose={handleClose}
        userType={userType}
        userInfo={userInfo}
        farmerInfo={farmerInfo}
        checkList={checkList}
        setCheckList={setCheckList}
        addToExistingOrder={addToExistingOrder}
        setAddToExistingOrder={setAddToExistingOrder}
        grandTotal={grandTotal}
        customerContribution={customerContribution}
        donationSubsidy={donationSubsidy}
        marketplaceMessageEnabled={marketplaceMessageEnabled}
        setMarketplaceMessageEnabled={setMarketplaceMessageEnabled}
        marketplaceMessage={marketplaceMessage}
        setMarketplaceMessage={setMarketplaceMessage}
        pickupLocation={pickupLocation}
        costTotal={costTotal}
        setDonationSubsidy={setDonationSubsidy}
        suggestedFees={suggestedFees}
        subsidyAmount={subsidyAmount}
        setCustomerContribution={setCustomerContribution}
        packageFeeTotal={packageFeeTotal}
        deliveryFee={deliveryFee}
        cashPortion={cashPortion}
        subsidyExplanation={subsidyExplanation}
        dollarColour={dollarColour}
        textFieldColour={textFieldColour}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
      />
    </div>
  );
}
