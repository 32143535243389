// This loads a community member from the database.  We use this to get their
// information.
import firebase from "../../components/Firebase.js";

export default function loadCommunityMember(
  searchName,
  setCommunityMembers,
  setLoading,
) {
  const database = firebase.firestore();
  // Holds the orders of the distribution location
  const names = searchName.split(" ");
  // Community Member temporary.
  const communityMembersFoundTemp = {};

  // If the first name is undefined then set it to nothing.
  if (names[0] === undefined) {
    names[0] = "";
  }

  // If the last name is undefined then set it to nothing.
  if (names[1] === undefined) {
    names[1] = "";
  }

  setLoading(true);

  // Set the communityMember doc ref.
  let communityMemberDocRef = database
    .collection("Users")
    .where("firstName", "==", names[0]);

  // If the last name is not equal to nothing then search for the first and last name.
  if (names[1] !== "") {
    // Load the volunteer positions from the database and add them to the hook volunteerJobs.
    communityMemberDocRef = database
      .collection("Users")
      .where("firstName", "==", names[0])
      .where("lastName", "==", names[1]);
  }

  // Load the user's user doc and then add their information to be used.
  communityMemberDocRef
    .get()
    .then((collection) => {
      collection.docs.forEach((doc) => {
        if (doc.exists) {
          const dictKey = [doc.data().userId].join("-");
          communityMembersFoundTemp[dictKey] = { ...doc.data() };
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });

      // Sort CommunityMembers after fetching
      const sortedCommunityMembers = Object.keys(
        communityMembersFoundTemp,
      ).sort((a, b) => {
        const nameA =
          communityMembersFoundTemp[a].firstName.toLowerCase() +
          communityMembersFoundTemp[a].lastName.toLowerCase();
        const nameB =
          communityMembersFoundTemp[b].firstName.toLowerCase() +
          communityMembersFoundTemp[b].lastName.toLowerCase();
        return nameA.localeCompare(nameB);
      });

      setCommunityMembers(
        sortedCommunityMembers.reduce((acc, key) => {
          acc[key] = communityMembersFoundTemp[key];
          return acc;
        }, {}),
      );
      setLoading(false);
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      setLoading(false);
    });
}
