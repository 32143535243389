// This is used to update the balance of the wallet.  It loads the wallet,
// then checks to see if exists.  If not it'll create a new one otherwise it
// will update the existing one.
import firebase from "../../../components/Firebase.js";
import CreateUserWallet from "./CreateUserWallet.js";
import CommunityMemberTransaction from "./CommunityMemberTransaction.js";

export default async function updateWalletBalance(
  user,
  amount,
  description,
  communityWallet,
  setCommunityWallet,
) {
  // Checks to see if the userId was sent.  Without then we can't do anything.
  if (!user) {
    console.error("User ID is required to update wallet balance.");
    return 0;
  }
  // Checks to see if the amount was not 0.  If it's 0 then there is nothing
  // to do.
  else if (amount !== 0) {
    // Checks the days date.
    const today = new Date().toISOString().split("T")[0];
    // Finds if the amount is a credit or a debit.
    const transactionType = amount > 0 ? "credit" : "debit";
    // Since we store if it's a credit of a debit we want the absolute amount.
    const amountAbs = Math.abs(amount);
    // Create the transaction object.
    const transaction = {
      transactionType,
      date: today,
      amount: amountAbs,
      description,
    };
    // Creates the databse.
    const database = firebase.firestore();
    const walletsDocRef = database
      .collection("CommunityWallets")
      .doc(user.split("-")[2]);

    try {
      const walletDoc = await walletsDocRef.get();
      // If the wallet doesn't exist then we have to create it.
      if (!walletDoc.exists) {
        // If the wallet doesn't exist, create it with the amount we
        // need.
        const newWallet = CreateUserWallet(
          user.split("-")[0],
          user.split("-")[1],
          user.split("-")[2],
          amount,
          transaction,
        );
        // Set the wallet.
        setCommunityWallet({ ...newWallet });
      }
      // Create a transaction for the wallet.
      else {
        const updatedWallet = await CommunityMemberTransaction(
          amount,
          communityWallet,
          description,
        );
        setCommunityWallet({ ...updatedWallet });
      }
    } catch (error) {
      console.error("Error updating wallet balance:", error);
      throw new Error(error.message || "Error updating wallet balance");
    }
  } else {
    return 0;
  }
}
