// This is used to determine the button for the create wallet and if the user
// already has a wallet it won't let us create a new one.
import React from "react";
import Button from "@material-ui/core/Button";
import CreateUserWallet from "../../../functions/Firestore/Wallet/CreateUserWallet.js";

export default function CreateMemberWalletButton({
  firstName,
  lastName,
  userId,
  alreadyAdded,
  communityWallets,
  setCommunityWallets,
}) {
  const handleClick = () => {
    const communityWalletsTemp = communityWallets;
    const newWallet = CreateUserWallet(firstName, lastName, userId);
    communityWalletsTemp.push(newWallet);
    setCommunityWallets([...communityWalletsTemp]);
  };

  return (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      {" "}
      {/* Flex container */}
      {alreadyAdded ? (
        <Button disabled>ALREADY CREATED</Button>
      ) : (
        <Button
          onClick={handleClick}
          style={{ color: "green", fontWeight: "bold" }} // Change font color and make it bold
        >
          CREATE
        </Button>
      )}
    </div>
  );
}
