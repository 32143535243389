// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useContext } from "react";
import "../App.css";
import "../styles/Marketplace.css";
import LoadMarketplaceItems from "../functions/LoadMarketplaceItems.js";
import firebase from "../components/Firebase.js";
import MarketplaceBulkAmounts from "../functions/MarketplaceBulkAmounts.js";
import NextPickupDay from "../functions/NextPickupDay.js";
import { AuthContext } from "../components/authentication/Auth.js";
import LoadFarmDetails from "../functions/Firestore/LoadFarmDetails.js";
import CreateFarmCategories from "../functions/CreateFarmCategories.js";
import LoadCategories from "./MarketplacePage/LoadCategories.js";
// eslint-disable-next-line no-unused-vars
import IndividualMarketplace from "./MarketplacePage/UserViews/IndividualMarketplace.jsx";
// eslint-disable-next-line no-unused-vars
import DistributionLocationMarketplace from "./MarketplacePage/UserViews/DistributionLocationMarketplace.jsx";
// eslint-disable-next-line no-unused-vars
import RestaurantMarketplace from "./MarketplacePage/UserViews/RestaurantMarketplace.jsx";
// eslint-disable-next-line no-unused-vars
import AdminMarketplace from "./MarketplacePage/UserViews/AdminMarketplace.jsx";
// eslint-disable-next-line no-unused-vars
import NoSignInMarketplace from "./MarketplacePage/UserViews/NoSignInMarketplace.jsx";
import FilterDisplayedItems from "./MarketplacePage/Functions/FilterDisplayedItems.js";
// eslint-disable-next-line no-unused-vars
import LoadingContent from "../components/LoadingContent.jsx";

// This includes props because if the user is coming from another page there may
// be a query that they need to receive.
function Marketplace(props) {
  const { currentUser, userInfo, handleUserInfoChange } =
    useContext(AuthContext);

  // The hook where the data downloaded from the database is stored.  It is intialized
  // to have the contents of a document that is empty.  That way the map function doesn't
  // complain
  const [foodItems, setFoodItems] = useState([]);

  // These are the foodItems that are displayed after the filters have been put in
  // place
  const [displayedFoodItems, setDisplayedFoodItems] = useState([]);

  // This is set to false until the data from the database been loaded
  const [loading, setLoading] = useState(false);

  // This is set to false until the farm's data from the database been loaded
  const [farmLoading, setFarmLoading] = useState(false);

  // This is set to false until the data from the database been loaded
  const [foodItemsLoading, setFoodItemsLoading] = useState(false);

  // This is set to false until the data from the database been loaded
  const [pantryLoading, setPantryLoading] = useState(false);

  // This contains the documents and the data of all the farms on the marketplace
  const [farmDetails, setFarmDetails] = useState([]);

  // Saves whether the snackBar that displays after placing order is open or not
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  // Message in snackBar.
  const [snackBarMessage, setSnackBarMessage] = useState(
    "Items have been added to your cart",
  );

  const database = firebase.firestore();

  // This is the last loaded item for each farm.  Each farm has a last loaded item.
  // When the user scrolls it will save the last loaded item of each farm
  const [lastLoadedItem, setLastLoadedItem] = useState({});

  // Limits the number of food Items loaded for each farm to 8
  const loadLimit = 100;

  // This is used to indicate the pickupLocationFarms of a user that isn't signed in.
  const [pickupLocation, setPickupLocation] = useState({
    ...props.location.query,
  });

  // This stores the details of the pickup location so they can be used later on.
  const [pickupLocationDetails, setPickupLocationDetails] = useState({
    ...props.location.query,
  });

  // This stores a dictionary with the keys as all the farms on the marketplace and
  // the value as an array of all the categories from that farm.
  const [farmCategories, setFarmCategories] = useState({});

  // This stores an array of all the categories no repeats of the marketplace.
  const [marketplaceCategories, setMarketplaceCategories] = useState({});

  // This stores the users selected category filter.
  const [selectedCategory, setSelectedCategory] = useState("All Products");

  // This stores a dictionary of all the farms with a value of a boolean for whether
  // or not they're checked.
  const [checkedFarms, setCheckedFarms] = useState([]);

  // This useEffect listens for any changes to the foodItems hook and sets the
  // farmCategories hook.  This is only for admin and distributionLocation users.
  useEffect(() => {
    if (
      userInfo.userType === "admin" ||
      userInfo.userType === "distributionLocation"
    ) {
      // Sends in the foodItems and returns the farm categories.
      const farmCategoriesTemp = CreateFarmCategories(foodItems);
      setFarmCategories(farmCategoriesTemp);
      // Resets the FilterDisplayedItems everytime the foodItems changes to make
      // sure they match.
      FilterDisplayedItems(
        foodItems,
        setDisplayedFoodItems,
        selectedCategory,
        checkedFarms,
      );
    }
  }, [foodItems]);

  useEffect(() => {
    // This is used to grab the data of foodItems from the database.  It only grabs
    // the foodItems that the user has in their marketplace.
    let docRef = "";

    // This is used because when you use setPickupLocation it won't be quick enough
    // to the use pickupLocation in the useEffect so this is created to be used here
    // and then updated pickupLocation for later use in the program.
    let pickupLocationTemp = [];

    // Sets the user pickup location.
    let userInfoPickupLocation = userInfo.pickupLocation;

    // Checks if the user's pickup location is a community location. First checking
    // that it has a pickup location in the first place.
    if (userInfo.pickupLocation !== undefined) {
      // Checks if the user selected a community location.
      if (userInfo.pickupLocation.pickupLocation !== undefined) {
        // Sets the Pickup location to the distribution location.
        userInfoPickupLocation = userInfoPickupLocation.pickupLocation;
      }
    }

    // Depending on the user the pickupLocation farms are saved differently.  If
    // the user is an individual their pickupLocation farms are saved in
    // userInfo.pickupLocation.farms.
    if (userInfo.userType === "individual") {
      // If the user is new they might not have picked a pickup Location yet.  If
      // they have then set the pickupLocationTemp to include the farms pickup dates
      // which tell the marketplace if it should display pantry items or the farms
      // foodItems
      if (userInfoPickupLocation) {
        pickupLocationTemp = Object.keys(
          userInfoPickupLocation.farmsPickupDates,
        );
        // Sets the pickup location details in case it needs to be used by an
        // empty marketplace
        setPickupLocationDetails({ ...userInfoPickupLocation });
      }
    }
    // if the user isn't signed in they have to select a distribution location
    // and then a query is sent back through the Link component so the farms are
    // saved in pickupLocation which was intitialised from the props.location.query
    else if (!currentUser) {
      // Had to use {...props.location.query} directly as when a user would log out
      // while looking at the marketplace the pickupLocation and pickupLocationDetails
      // wouldn't update quick enough to remove the logged in user's info.
      if (Object.keys({ ...props.location.query }).length !== 0) {
        if (
          Object.keys({ ...props.location.query }.farmsPickupDates).length !== 0
        ) {
          pickupLocationTemp = Object.keys(
            { ...props.location.query }.farmsPickupDates,
          );
        } else {
          pickupLocationTemp = ["No Farms"];
        }
      }
    }
    // If the user is a distribution Location then the farm's are saved in it's
    // own userInfo.  Cycle through them to add just the farmNames.
    else if (userInfo.userType === "distributionLocation") {
      // eslint-disable-next-line no-empty
      if (userInfo.farms[0] === "No farms available please contact developer") {
      } else {
        userInfo.farms.forEach((farm) => {
          pickupLocationTemp.push(farm.farmName);
        });
      }
    }
    // Otherwise the user is a restaurant or admin and they will load their foodItems differently.
    else {
      pickupLocationTemp = [];
    }
    // set the hook to equal this temp value so it is saved and can be used later
    setPickupLocation([...pickupLocationTemp]);

    // If the user is not signed in, an individual, or a distributionLocation then
    // load the foodItems based on the farms the pickup location uses.
    if (
      !currentUser ||
      userInfo.userType === "individual" ||
      userInfo.userType === "distributionLocation"
    ) {
      // Use either pickupLocation, which is set on page load if the user just went
      // and selected a distribution location, or userInfo.pickupLocation if the user
      // has it from being logged in and selecting one before, to find out the
      // next pickupDay.
      let pickupLocationDetailsTemp = null;
      // Intitialised at page load
      if (userInfoPickupLocation === undefined) {
        pickupLocationDetailsTemp = pickupLocation;
      }
      // From the users saved pickupLocation
      else {
        pickupLocationDetailsTemp = userInfoPickupLocation;
      }

      // This sends in the distributionLocation's details and puts out an array
      // with all the food categories the location provides through it's farmers.
      const marketplaceCategoriesTemp = LoadCategories(
        pickupLocationDetailsTemp,
      );
      setMarketplaceCategories(marketplaceCategoriesTemp);
      // Check if the pickupLocation has any farms if not then this will be updated
      // later and the user will have to come back.
      if (Object.keys(pickupLocationTemp).length !== 0) {
        // Sends in the day of the week that is given as the pickupDay of the distribution
        // location and then returns the MM-DD-YYYY of the next pickup date
        const pickupDay = NextPickupDay(pickupLocationDetailsTemp.pickupDay);

        // Stores an array of the farm names that need to be loaded to show pantry
        // items from them.
        const loadLeftovers = [];

        // Cycle through each farm sorting the data so that it can be paginated
        pickupLocationTemp.forEach((farm, index) => {
          let lastFarm = false;
          let showCatalogue = true;
          // Sets all the checkedFarms to false.
          setCheckedFarms((prevState) => {
            return { ...prevState, [farm]: false };
          });

          if (index === pickupLocationTemp.length - 1) {
            lastFarm = true;
          }
          // If the user is a distribution Location then always show the farms
          // items and not the pantry ones.  If the user is an individual do the else
          if (userInfo.userType === "distributionLocation") {
            // Since the distribution location doesn't need to the farm data just
            // set it to true
            setFarmLoading(true);
            LoadMarketplaceItems(
              farm,
              loadLimit,
              lastFarm,
              lastLoadedItem,
              setFoodItems,
              setDisplayedFoodItems,
              setLastLoadedItem,
              setLoading,
              userInfo,
              pickupLocationDetails,
            );
          } else {
            // Call this function by sending in the array of farm names included
            // on the marketplace and set the hook farmDetails to include their
            // details
            LoadFarmDetails(pickupLocationTemp, setFarmDetails, setFarmLoading);

            // Check to see if the farmsPickupDates matches the next pickup day
            // of this distributionLocation.  If it does then add this farm to be
            // loaded later from the pantry.
            if (
              pickupLocationDetailsTemp.farmsPickupDates[farm] === pickupDay
            ) {
              loadLeftovers.push(farm);
              if (!pickupLocationDetailsTemp.farmsCatalogueAndPantry[farm]) {
                showCatalogue = false;
              }
            }
            // Check to see if this location is supposed to show the catalogue.
            if (showCatalogue) {
              // Otherwise load the items from the foodItems collection
              LoadMarketplaceItems(
                farm,
                loadLimit,
                lastFarm,
                lastLoadedItem,
                setFoodItems,
                setDisplayedFoodItems,
                setLastLoadedItem,
                setLoading,
                userInfo,
                pickupLocationDetails,
              );
            }
          }

          // On the last farm load the leftover items from the pantry
          if (!!lastFarm && loadLeftovers.length > 0) {
            setLoading(true);
            MarketplaceBulkAmounts(
              pickupLocationDetailsTemp,
              loadLeftovers,
              setFoodItems,
              setDisplayedFoodItems,
              setPantryLoading,
              marketplaceCategoriesTemp,
              setMarketplaceCategories,
              userInfo,
            );
          } else if (!!lastFarm && loadLeftovers.length === 0) {
            setPantryLoading(true);
          }
        });
      }
      // If the pickup location has no farms then there is nothing to load and loading
      // is done
      else {
        // If there are no farms then set this to true
        setFarmLoading(true);
        setLoading(true);
        setPantryLoading(true);
      }
    }
    // If the user isn't any of the above choices then it's a restaurant.
    else {
      // The restaurant have their own farms and so they only will see those farms.
      docRef = database
        .collection("FoodItems")
        .where("userId", "==", currentUser.uid);

      const dataTransfer = [];
      docRef
        .get()
        .then((collection) => {
          collection.docs.forEach((doc) => {
            if (doc.exists) {
              const foodItemTemp = doc.data();
              foodItemTemp.quantity = 0;
              dataTransfer.push(foodItemTemp);
              console.log("Database read!");
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          });
          if (dataTransfer.length !== 0) {
            setFoodItems(dataTransfer);
            setDisplayedFoodItems(dataTransfer);
          }
          // Sets all the checkedFarms to false.
          userInfo.farms.forEach((farm) => {
            setCheckedFarms((prevState) => {
              return { ...prevState, [farm]: false };
            });
          });
          // A restaurant doesn't need to load the farms so set this to true.
          setFarmLoading(true);
          // A restaurant doesn't need to load the pantry items so set this to true.
          setPantryLoading(true);
          setLoading(true);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          setFarmLoading(true);
          setLoading(true);
        });
    }
  }, [database, userInfo.pickupLocation]);

  // Once the foodItems are done loading then we just need to remove any duplicates
  // between the foodItems in case the pantry had any.
  useEffect(() => {
    // Check that we're done all our loading.
    if (loading && farmLoading && pantryLoading) {
      // Check to see if the user was an individual.
      if (userInfo.userType === "individual" || userInfo.userType == null) {
        // Set the final foodItems array.
        const foodItemsFinal = [];
        // Cycle through the foodItems.
        foodItems.forEach((foodItem) => {
          // See if this foodItem exists in the array.
          const foodItemIndex = foodItemsFinal.findIndex((foodItemFinal) => {
            return (
              foodItem.farmName === foodItemFinal.farmName &&
              foodItem.individualDescription ===
                foodItemFinal.individualDescription &&
              foodItem.item === foodItemFinal.item
            );
          });
          // If it doesn't exist push the item to the array.
          if (foodItemIndex === -1) {
            foodItemsFinal.push(foodItem);
          } else {
            // Check to see if the item has a limit and if it does then we want
            // to use the pantry foodItems first.
            if (foodItem.limit) {
              foodItemsFinal[foodItemIndex] = foodItem;
            }
          }
        });

        // Sort foodItems to be in order of farmNames.
        foodItemsFinal.sort((a, b) => {
          if (a.farmName < b.farmName) {
            return -1;
          }
          if (a.farmName > b.farmName) {
            return 1;
          }
          return 0;
        });

        setFoodItems([...foodItemsFinal]);
        setDisplayedFoodItems([...foodItemsFinal]);
        setFoodItemsLoading(true);
      } else {
        setFoodItemsLoading(true);
      }
    }
  }, [loading, farmLoading, pantryLoading]);

  // handles the Saved any changed snackbar closing
  function handleSnackBarClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  }

  // Checks if the data has been loaded yet
  if (foodItemsLoading) {
    // Only do this if the user is signed in.
    if (currentUser) {
      let totalBasketItems = 0;
      userInfo.basketItems.forEach((item) => {
        totalBasketItems += item.quantity;
      });
      if (userInfo.userType === "individual") {
        return (
          <IndividualMarketplace
            userInfo={userInfo}
            handleUserInfoChange={handleUserInfoChange}
            farmDetails={farmDetails}
            foodItems={foodItems}
            displayedFoodItems={displayedFoodItems}
            setDisplayedFoodItems={setDisplayedFoodItems}
            currentUser={currentUser}
            marketplaceCategories={marketplaceCategories}
            totalBasketItems={totalBasketItems}
            pickupLocationDetails={pickupLocationDetails}
            snackBarOpen={snackBarOpen}
            setSnackBarOpen={setSnackBarOpen}
            snackBarMessage={snackBarMessage}
            setSnackBarMessage={setSnackBarMessage}
            handleSnackBarClose={handleSnackBarClose}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            checkedFarms={checkedFarms}
            setCheckedFarms={setCheckedFarms}
          />
        );
      } else if (userInfo.userType === "distributionLocation") {
        return (
          <DistributionLocationMarketplace
            userInfo={userInfo}
            handleUserInfoChange={handleUserInfoChange}
            foodItems={foodItems}
            setFoodItems={setFoodItems}
            displayedFoodItems={displayedFoodItems}
            setDisplayedFoodItems={setDisplayedFoodItems}
            currentUser={currentUser}
            farmCategories={farmCategories}
            totalBasketItems={totalBasketItems}
            snackBarOpen={snackBarOpen}
            setSnackBarOpen={setSnackBarOpen}
            snackBarMessage={snackBarMessage}
            setSnackBarMessage={setSnackBarMessage}
            handleSnackBarClose={handleSnackBarClose}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            checkedFarms={checkedFarms}
            setCheckedFarms={setCheckedFarms}
          />
        );
      } else if (userInfo.userType === "restaurant") {
        return (
          <RestaurantMarketplace
            userInfo={userInfo}
            handleUserInfoChange={handleUserInfoChange}
            foodItems={foodItems}
            displayedFoodItems={displayedFoodItems}
            setDisplayedFoodItems={setDisplayedFoodItems}
            currentUser={currentUser}
            totalBasketItems={totalBasketItems}
            snackBarOpen={snackBarOpen}
            setSnackBarOpen={setSnackBarOpen}
            handleSnackBarClose={handleSnackBarClose}
          />
        );
      } else if (userInfo.userType === "admin") {
        return (
          <AdminMarketplace
            userInfo={userInfo}
            handleUserInfoChange={handleUserInfoChange}
            foodItems={foodItems}
            setFoodItems={setFoodItems}
            displayedFoodItems={displayedFoodItems}
            setDisplayedFoodItems={setDisplayedFoodItems}
            currentUser={currentUser}
            farmCategories={farmCategories}
            totalBasketItems={totalBasketItems}
            snackBarOpen={snackBarOpen}
            setSnackBarOpen={setSnackBarOpen}
            snackBarMessage={snackBarMessage}
            setSnackBarMessage={setSnackBarMessage}
            handleSnackBarClose={handleSnackBarClose}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            checkedFarms={checkedFarms}
            setCheckedFarms={setCheckedFarms}
          />
        );
      } else {
        return <LoadingContent />;
      }
    } else {
      const noSignInUser = { userType: "individual" };
      return (
        <NoSignInMarketplace
          noSignInUser={noSignInUser}
          farmDetails={farmDetails}
          foodItems={foodItems}
          displayedFoodItems={displayedFoodItems}
          setDisplayedFoodItems={setDisplayedFoodItems}
          currentUser={currentUser}
          marketplaceCategories={marketplaceCategories}
          pickupLocationDetails={pickupLocationDetails}
          pickupLocation={pickupLocation}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          checkedFarms={checkedFarms}
          setCheckedFarms={setCheckedFarms}
        />
      );
    }
  } else {
    return <LoadingContent />;
  }
}

export default Marketplace;
