// This component is called by the indivdiual basket.  This is placed on the basket
// page and shows the user's order summary including the farms
// being paid and their fees along with the subtotal.
import React, { useEffect, useState, useContext } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import FarmTotalLineIndividual from "../Components/FarmTotalLineIndividual.jsx";
import ConfirmOrderDialog from "../../../components/dialogs/ConfirmOrder.js";
import { NonFoodCostsContext } from "../../Basket.js";
import AnnouncementDialog from "./Dialogs/AnnouncementDialog.jsx";
import QuestionnaireDialog from "./Dialogs/QuestionnaireDialog.jsx";
import PropTypes from "prop-types";
import CalcMaxSubsidy from "../Functions/UserInputs/CalcMaxSubsidy.js";
import AdditionalFees from "./AdditionalFees.jsx";

// This is a little square that is shown on the indivdiual user's basket page.
// It provides the user with information on their order.
export default function OrderSummary({
  userInfo,
  currentStatus,
  farmTotals,
  packageTotals,
  combinedFarmList,
  customerContribution,
  setDonationSubsidy,
  setCustomerContribution,
  dollarColour,
  donationSubsidy,
  textFieldColour,
  costTotal,
  grandTotal,
  handlePlaceOrder,
  deliveryFee,
  suggestedFees,
}) {
  // This holds the state of whether or not the notice will open.
  const [openNotice, setOpenNotice] = useState(false);

  const { cashPortion } = useContext(NonFoodCostsContext);
  // The amount of subsidy they have available without matching
  const [freeSubsidyAmount, setFreeSubsidyAmount] = useState(0);
  // The amount of subsidy the user has used this week already.
  const [usedSubsidyAmount, setUsedSubsidyAmount] = useState(0);
  // The subsidy they have available including the matching portion.
  const [subsidyAmount, setSubsidyAmount] = useState(0);
  // The pickup location that is a distribution location.
  let pickupLocation = userInfo.pickupLocation;
  // The package fee total which we set to 0.
  let packageFeeTotal = 0;

  // Sets the community location.
  const communityLocation = userInfo.pickupLocation;

  // Checks to see if the user has a community location and if so then update the
  // pickup location to the distribution location.
  if (communityLocation.pickupLocation) {
    pickupLocation = communityLocation.pickupLocation;
  }

  const subsidyExplanation = `Our subsidy program is designed to assist those in need, thanks to the generosity of our community members. Each user can access a maximum subsidy of $${pickupLocation.subsidyAmount.toFixed(
    2,
  )}. If a user contributes an equal amount through matching, they can access the full subsidy every week. If a user is unable to contribute financially, the subsidy resets to its maximum every ${
    pickupLocation.freqOfFreeSubsidy
  } weeks. This system encourages both receiving and giving within our community while ensuring that everyone has access to essential assistance to support our farmers.`;

  // The dictionary that says which questionnaires are currently active and their creation date.
  // {questionnaireCreationDate : DateQuestionnaireWasAdded}
  let questionnaireList = pickupLocation.questionnaireList;

  // If questionnaireList doesn't exist then set it to an empty dictionary.
  if (questionnaireList === undefined) {
    questionnaireList = {};
  }

  // User's total credit balance.
  let userCredits = userInfo.credits;
  // If the user doesn't have this key then set their credits to 0.
  if (userCredits === undefined) {
    userCredits = 0;
  }

  // Cycle through the package Totals and add them all up.
  Object.keys(packageTotals).forEach((packageTotal) => {
    packageFeeTotal += packageTotals[packageTotal];
  });

  // Finds the user's past orders and determines how much free subsidy
  // they have available.
  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    const fetchSubsidy = async () => {
      try {
        const [freeSubsidy, usedSubsidy] = await CalcMaxSubsidy(
          userInfo,
          pickupLocation,
        );
        if (isMounted) {
          setFreeSubsidyAmount(freeSubsidy);
          setUsedSubsidyAmount(usedSubsidy);
        }
      } catch (error) {
        console.error("Error fetching subsidy:", error);
      }
    };

    fetchSubsidy();

    // Cleanup function to run when the component unmounts
    return () => {
      isMounted = false; // Set the flag to false when unmounting
    };
  }, []);

  // This is used to update the subsidy available when a user changes
  // their order which may affect how much subsidy can be matched.
  useEffect(() => {
    // The total cost of the order excluding the donation/subsidy.
    const totalCostIncFees =
      packageFeeTotal +
      costTotal +
      parseFloat(deliveryFee) +
      parseFloat(cashPortion);

    // Calculate how much the user could matched after using all
    // of the free subsidy.  If the free subsidy is greater than
    // ability to match then this won't add to their max subsidy.
    let matchedPortion = (totalCostIncFees - freeSubsidyAmount) / 2;
    if (matchedPortion < 0) {
      matchedPortion = 0;
    }

    // The subsidy available is the free subsidy amount plus their
    // matched portion.
    let subsidyAmountTemp = freeSubsidyAmount + matchedPortion;

    // Make sure that their subsidy doesn't go above the available
    // subsidy amount even if they spend more.
    if (subsidyAmountTemp > pickupLocation.subsidyAmount) {
      subsidyAmountTemp = pickupLocation.subsidyAmount;
    }

    // The usedSubsidyAmount is how much the user used already
    // this week so we will subtract that from their total regardless.
    subsidyAmountTemp += usedSubsidyAmount;
    // If their subsidy goes below 0 for some ready then make it
    // 0.
    if (subsidyAmountTemp < 0) {
      subsidyAmountTemp = 0;
    }
    // Make sure that the subsidy amount doesn't go above the max amount.
    else if (subsidyAmountTemp > pickupLocation.subsidyAmount) {
      subsidyAmountTemp = pickupLocation.subsidyAmount;
    }

    setSubsidyAmount(subsidyAmountTemp);
  }, [
    freeSubsidyAmount,
    usedSubsidyAmount,
    costTotal,
    cashPortion,
    deliveryFee,
  ]);

  return (
    <div className="Order-Summary-Box">
      {pickupLocation === undefined ? (
        <div>
          <h3 className="Header-3">Your Pickup Location</h3>
          <p>
            Please select a location
            <Link
              to={{
                pathname: "/DistributionLocationSelection",
                query: "/Basket",
              }}
            >
              <Button color="primary">CHANGE</Button>
            </Link>
          </p>
        </div>
      ) : (
        <div>
          <h3>Order Summary</h3>
          <h5>
            <u>Total Paid to Vendors </u>
          </h5>

          <div className="Checkout">
            <div className="Totals-And-Button">
              {Object.keys(farmTotals).map((farmTotal, index) =>
                FarmTotalLineIndividual(
                  farmTotals,
                  farmTotal,
                  combinedFarmList,
                  index,
                  userInfo.userType,
                  packageTotals,
                ),
              )}
              <AdditionalFees
                costTotal={costTotal}
                pickupLocation={pickupLocation}
                suggestedFees={suggestedFees}
                packageFeeTotal={packageFeeTotal}
                communityLocation={communityLocation}
                currentStatus={currentStatus}
                deliveryFee={deliveryFee}
                userInfo={userInfo}
              />
            </div>
          </div>

          <p className="Total-Owed-Font">Subtotal : ${grandTotal.toFixed(2)}</p>

          {currentStatus[0] !== "loading" &&
          communityLocation.maxParticipants < currentStatus[1] &&
          !currentStatus[2] ? (
            <Button disabled>LOCATION FULL</Button>
          ) : (
            <ConfirmOrderDialog
              message="PROCEED TO PAYMENT"
              placeOrder={handlePlaceOrder}
              userInfo={userInfo}
              farmTotals={farmTotals}
              farmerInfo={combinedFarmList}
              grandTotal={grandTotal}
              customerContribution={customerContribution}
              donationSubsidy={donationSubsidy}
              setOpenNotice={setOpenNotice}
              pickupLocation={pickupLocation}
              costTotal={costTotal}
              setDonationSubsidy={setDonationSubsidy}
              suggestedFees={suggestedFees}
              subsidyAmount={subsidyAmount}
              setCustomerContribution={setCustomerContribution}
              packageFeeTotal={packageFeeTotal}
              deliveryFee={deliveryFee}
              cashPortion={cashPortion}
              subsidyExplanation={subsidyExplanation}
              dollarColour={dollarColour}
              textFieldColour={textFieldColour}
            />
          )}
          <AnnouncementDialog
            openNotice={openNotice}
            setOpenNotice={setOpenNotice}
            pickupLocation={pickupLocation}
          />
          {Object.keys(questionnaireList).map((docKey, index) => (
            <QuestionnaireDialog
              key={index}
              openNotice={openNotice}
              docKey={parseInt(docKey)}
              pickupLocation={pickupLocation}
              userId={userInfo.userId}
            />
          ))}
        </div>
      )}
    </div>
  );
}

OrderSummary.propTypes = {
  userInfo: PropTypes.object,
  currentStatus: PropTypes.array,
  farmTotals: PropTypes.object,
  packageTotals: PropTypes.object,
  combinedFarmList: PropTypes.array,
  customerContribution: PropTypes.string,
  setDonationSubsidy: PropTypes.func,
  setCustomerContribution: PropTypes.func,
  dollarColour: PropTypes.string,
  donationSubsidy: PropTypes.number,
  textFieldColour: PropTypes.string,
  costTotal: PropTypes.number,
  grandTotal: PropTypes.number,
  handlePlaceOrder: PropTypes.func,
  deliveryFee: PropTypes.number,
  suggestedFees: PropTypes.number,
};
