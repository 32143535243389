// This is used to search for a community member to create a wallet for.
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingContent from "../../../components/LoadingContent.jsx";
import loadCommunityMember from "../../../functions/Firestore/loadCommunityMember.js";
import CreateMemberWalletButton from "./CreateMemberWalletButton.jsx";

// This is the dialog box that opens when a user clicks the add button
function AddCommunityMemberBox({
  open,
  onClose,
  communityWallets,
  setCommunityWallets,
}) {
  // The name of the user you are searching for.
  const [searchName, setSearchName] = useState("");
  // The search is loading.
  const [searchLoading, setSearchLoading] = useState(false);
  // The volunteers found from the search
  const [communityMembersFound, setCommunityMembersFound] = useState({});

  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = () => {
    onClose(false);
  };

  // Function to handle the search action
  const handleSearch = () => {
    setSearchLoading(true);
    loadCommunityMember(searchName, setCommunityMembersFound, setSearchLoading);
  };

  useEffect(() => {
    const communityMembersFoundUserIds = Object.keys(communityMembersFound);
    const communityMembersFoundTemp = { ...communityMembersFound };

    if (!searchLoading) {
      communityMembersFoundUserIds.forEach((userId) => {
        if (communityWallets.some((obj) => obj.userId === userId)) {
          communityMembersFoundTemp[userId].alreadyAdded = true;
        } else {
          communityMembersFoundTemp[userId].alreadyAdded = false;
        }
      });

      setCommunityMembersFound({ ...communityMembersFoundTemp });
    }
  }, [searchLoading, communityWallets]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <style>
        {`
              .volunteer-list {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px 0;
              }
              .volunteer-details {
                flex-grow: 1;
              }
            `}
      </style>
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Create Wallet for Community Members
      </DialogTitle>
      <DialogContent>
        <div>
          <div className="search-bar">
            <style>
              {`
                      .search-bar {
                        display: flex;
                        align-items: center;
                        gap: 10px; /* Adds space between the TextField and the Button */
                      }
  
                      .search-input {
                        flex-grow: 1; /* Makes the input take the remaining space */
                      }
                    `}
            </style>

            <TextField
              margin="dense"
              id="searchName"
              label="Volunteer Search"
              name="searchName"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              className="search-input"
              required
            />
            <Button
              onClick={handleSearch}
              variant="contained"
              size="small"
              color="primary"
            >
              ENTER
            </Button>
          </div>

          {!searchLoading ? (
            <div>
              {Object.keys(communityMembersFound).map(
                (communityMemberFound, index) => (
                  <div key={communityMemberFound}>
                    <div className="volunteer-list">
                      <span className="volunteer-details">
                        {communityMembersFound[communityMemberFound].firstName}{" "}
                        {communityMembersFound[communityMemberFound].lastName}
                        <br />
                        Email:{" "}
                        {communityMembersFound[communityMemberFound].email}
                      </span>
                    </div>
                    {index !==
                      Object.keys(communityMembersFound).length - 1 && (
                      <hr
                        style={{
                          width: "100%",
                          margin: "10px 0",
                          border: "1px solid #333",
                        }}
                      />
                    )}
                    {}
                    <CreateMemberWalletButton
                      firstName={
                        communityMembersFound[communityMemberFound].firstName
                      }
                      lastName={
                        communityMembersFound[communityMemberFound].lastName
                      }
                      userId={
                        communityMembersFound[communityMemberFound].userId
                      }
                      alreadyAdded={
                        communityMembersFound[communityMemberFound].alreadyAdded
                      }
                      communityWallets={communityWallets}
                      setCommunityWallets={setCommunityWallets}
                    />
                  </div>
                ),
              )}
            </div>
          ) : (
            <LoadingContent inline={true} />
          )}
        </div>

        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            CLOSE
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

AddCommunityMemberBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

// Search for a user that and their information to add them to the volunteer food system.
export default function CommunityMemberSearch({
  communityWallets,
  setCommunityWallets,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        style={{ backgroundColor: "green", color: "white" }}
        variant="contained"
        onClick={handleClickOpen}
      >
        CREATE NEW WALLET
      </Button>

      <AddCommunityMemberBox
        open={open}
        onClose={handleClose}
        communityWallets={communityWallets}
        setCommunityWallets={setCommunityWallets}
      />
    </div>
  );
}
