// This is used to update a community member's wallet.
import firebase from "../../../components/Firebase.js";

export default function CommunityMemberTransaction(
  amount,
  communityWallet,
  description,
) {
  // Finds today's date.
  const today = new Date().toISOString().split("T")[0];
  const db = firebase.firestore();
  // Load the wallets
  const walletRef = db
    .collection("CommunityWallets")
    .doc(communityWallet.userId); // Assuming userId is the document ID
  // determine if we are deducting or crediting the account.
  const transactionType = amount > 0 ? "credit" : "debit";
  // The amount is all we care about since we have the credit or debit saved.
  const amountAbs = Math.abs(amount);
  // The community wallet.
  const communityWalletTemp = { ...communityWallet };
  // Create the transaction.
  const transaction = {
    transactionType,
    date: today,
    amount: amountAbs,
    description,
  };
  // Update the transaction history and the user's total.
  return walletRef
    .update({
      transactionHistory: firebase.firestore.FieldValue.arrayUnion(transaction), // Adds new transaction
      Total: firebase.firestore.FieldValue.increment(amount), // Atomically updates total
    })
    .then(() => {
      // After the transaction has completed it will return the updated object
      // to refresh the page.
      console.log("Transaction successfully added!");
      communityWalletTemp.transactionHistory.push(transaction);
      communityWalletTemp.Total += amount;
      return communityWalletTemp;
    })
    .catch((error) => {
      console.error("Error updating wallet: ", error);
    });
}
